import React from "react";
import ButtonDropdown from "../ButtonDropdown";
// Impact UI
import { Button } from "impact-ui";

export default function ButtonDependentWithoutIcon({
  item,
  onAction,
  index,
  dependentInfo,
}) {
  if (item[`dependentOn`]) {
    return (
      <>
        {dependentInfo[`${item[`dependentOn`]}`] ? (
          <>
            {item["type"] === "buttonDropdown" ? (
              <ButtonDropdown data={item} isDivNotRequired={true} />
            ) : (
              <Button
                disabled={
                  item?.disabledBy
                    ? !dependentInfo[`${item["disabledBy"]}`]
                    : false
                }
                variant={item.varient === "contained"? "primary": item.varient === 'text'? "url": "secondary"}
                size={!item.size || item.size == "medium" ? "normal" : "small"}
                title={item.name}
                // disabled={obj.disableFeat && diable}
                onClick={() => {
                  if (Array.isArray(item.action)) {
                    item.action.forEach((action) => onAction(action, index));
                  } else onAction(item.action, index);
                }}
              >
                {item.name}
              </Button>
            )}
          </>
        ) : null}
      </>
    );
  }
}
