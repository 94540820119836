import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getStorage } from "../../../../utils/storage";

// material UI
import Switch from "@mui/material/Switch";
import { FormControl, FormControlLabel, Grid } from "@mui/material";
//  import Button from "@mui/material/Button";
import { CustomModal } from "../CustomModal";
// custom import
import Text from "../../../../utils/Text";
import DownloadFiles from "../DownloadFiles";
import { addNotifications } from "../../data";
import useAction from "../../../../utils/useAction";
import "./buttonStyle.css";

// customComponents
import SnackBar from "../../../../componentsV2/SnackBar";

// Impact UIgetIAIcon
import { Button } from "impact-ui";

// Different Button Components
import ButtonDependent from "./ButtonDependent";
import ButtonDependentWithoutIcon from "./ButtonDependentWithoutIcon";
import ButtonWithIconLabel from "./ButtonWithIconLabel";
import ButtonWithoutIcon from "./ButtonWithoutIcon";
import ButtonWithoutLabel from "./ButtonWithoutLabel";
import GroupOfButton from "./ButtonGroup";
import CustomDrawer from "../CustomDrawer";

import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DescriptionIcon from "@mui/icons-material/Description";

// get icon access
import { getButtonIcon } from "./buttonIcons/getButtonIcon";
import { getIAIcon } from "./buttonIcons/getButtonIAIcon";

const disableLinkStyle = {
  pointerEvents: "none",
  color: "grey",
  textDecoration: "none",
};

let flag = true;

const CustomActionButton = (props) => {
  const { data } = props;
  const params = props?.otherInfo?.params;
  const {
    selectedRowInfo,
    tableInfo,
    editActionInfo,
    dependentInfo,
    formEditedValues,
    columnsInfo,
    gridRefs,
    dropdownInfo,
    tableInfoSaved,
    dropdownSelectionData,
    checkedRows,
    accordionState,
    tabList,
    allTabList,
  } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const invokeAction = useAction();
  const [open, setOpen] = useState(false);
  const [itemIndex, setItemIndex] = useState(null);
  const [action, setAction] = useState(null);

  const [cellActionIndex, setCellActionIndex] = useState(() => {
    if (props?.otherInfo?.data) {
      const actionIndex = props?.otherInfo?.data["#actionIndex"];
      if (actionIndex !== "") {
        return parseInt(actionIndex);
      }
    }
    return null;
  });
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [toggleAccordion, setToggleAccordion] = useState(false);

  const onAction = (action, index) => {
    if (!action)
      return;
    if (action.actionName === "navigate") {
      if (action.otherActionName === "notifications") {
        addNotifications(action);
      }
      const _path = props?.otherInfo?.data["#path"];
      navigate(
        "/" +
          window?.location?.pathname?.split("/")[1] +
          "/" +
          window?.location?.pathname?.split("/")[2] +
          (_path || action.path)
      );
      if (action.sortBasedOnDropdown === "SORT_BASED_ON_DROPDOWN") {
        let tableParams = {};
        let tableData = [];
        if (dependentInfo[`${action.key1}`]) {
          tableData = tableInfo[`${action.parent_table_key}_${action.key1}`];
        }
        if (dependentInfo[`${action.key2}`]) {
          tableData = tableInfo[`${action.parent_table_key}_${action.key2}`];
        }
        tableParams[`${action.parent_table_key}`] = tableData;
        dispatch({
          type: "TABLE_DATA",
          payload: tableParams,
        });
      }
    } else if (action.actionName === "modal") {
      if (action.disableTabSwitch === true) {
        dispatch({
          type: "SHOW_ACTIVE_TAB",
          showActiveTab: false,
        });
      }
      setOpen(!open);
      setItemIndex(index);
      if (action.isStoreRequired) {
        let params = {};
        params[`${action.key}`] = props.otherInfo.uniqueKey;
        params[`selectedRowIndex`] = props.otherInfo["rowIndex"];
        dispatch({
          type: "FORM_EDITED_VALUES",
          payload: params,
        });
      }
    } else if (action.actionName === "Download") {
      dispatch({
        type: "EXPORT_TO_EXCEL",
        payload: true,
      });
    } else if (action.actionName === "click") {
      let flag = true;
      if (action.frequency == 1) flag = true;
      let params = {};

      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      } else {
        // if(action.otherKey){
        params[`${action.otherKey}`] = true;
        // }
      }
      if (action.hasOwnProperty("defaultToggle")) {
        params[`${action.key}`] = action.defaultToggle;
      }
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
      if (action.path) {
        navigate(
          "/" +
            window?.location?.pathname?.split("/")[1] +
            "/" +
            window?.location?.pathname?.split("/")[2] +
            action.path
        );
      }
      if (action.type === "tab-redirect") {
        dispatch({
          type: "TAB_REDIRECT",
          payload: {
            activeTabIndex: action.activeTabIndex,
            parentTabIndex: action.parentTabIndex,
            activeTabValue: action.activeTabValue,
            parentTabValue: action.parentTabValue,
          },
        });
      }
    } else if (action.actionName === "clickOnce") {
      if (flag) {
        let params = {};

        params[`${action.key}`] = dependentInfo[`${action.key}`]
          ? !dependentInfo[`${action.key}`]
          : true;
        if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
          params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
            ? !dependentInfo[`${action.otherKey}`]
            : true;
        } else {
          params[`${action.otherKey}`] = true;
        }

        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });

        if (action.alert) {
          setMessage(action.message);
          setShowMessage(true);
          if (action.otherActionName === "notifications") {
            addNotifications(action);
          }
          if (action.path) {
            setTimeout(() => {
              navigate(
                "/" +
                  window?.location?.pathname?.split("/")[1] +
                  "/" +
                  window?.location?.pathname?.split("/")[2] +
                  action.path
              );
            }, 1500);
          }
          if (action.sortBasedOnDropdown === "SORT_BASED_ON_DROPDOWN") {
            let tableParams = {};
            let tableData = [];
            if (dependentInfo[`${action.key1}`]) {
              tableData =
                tableInfo[`${action.parent_table_key}_${action.key1}`];
            }
            if (dependentInfo[`${action.key2}`]) {
              tableData =
                tableInfo[`${action.parent_table_key}_${action.key2}`];
            }
            tableParams[`${action.parent_table_key}`] = tableData;
            dispatch({
              type: "TABLE_DATA",
              payload: tableParams,
            });
          }
        } else if (action.actionName === "SHOW_CLUSTER") {
          let params = {};
          params[`showClusterLevelPLan`] = true;
          params[`dinnerware-store-cluster`] = true;
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: params,
          });
        } else if (action.actionName === "APPLY_FILTER_ACTION") {
          let params = {};
          params[`apply-action`] = true;
          params[`default-key`] = false;
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: params,
          });
        } else if (action.actionName === "CustomDisplayToggle") {
          let params = {};
          if (action.key === "toggle") {
            params[`default-key`] = false;
            params[`toggle`] = true;
          } else if (action.key === "default-key") {
            params[`default-key`] = true;
            params[`toggle`] = false;
          }
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: params,
          });
        } else if (action.actionName === "ENABLE_DISABLE_KEYS") {
          let params = {};
          params[action.key] = true;
          params[action.otherKey] = false;
          params[action.otherKey2] = action.otherKey2 ? false : null;
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: params,
          });
        } else if (action.actionName === "ENABLE_DISABLE_BUTTON_KEYS") {
          if (action.path) {
            setTimeout(() => {
              navigate(
                "/" +
                  window?.location?.pathname?.split("/")[1] +
                  "/" +
                  window?.location?.pathname?.split("/")[2] +
                  action.path
              );
            }, 0);
          }
          let params = {};
          params[action.key] = true;
          params[action.key1] = action.key1 ? true : null;
          params[action.key2] = action.key2 ? true : null;
          params[action.key3] = action.key3 ? true : null;
          params[action.key4] = action.key4 ? true : null;
          params[action.key5] = action.key5 ? true : null;
          params[action.key6] = action.key6 ? true : null;
          params[action.otherKey1] = action.otherKey1 ? false : null;
          params[action.otherKey2] = action.otherKey2 ? false : null;
          params[action.otherKey3] = action.otherKey3 ? false : null;
          params[action.otherKey4] = action.otherKey4 ? false : null;
          params[action.otherKey5] = action.otherKey5 ? false : null;
          params[action.otherKey6] = action.otherKey6 ? false : null;
          params[action.otherKey7] = action.otherKey7 ? false : null;
          params[action.otherKey8] = action.otherKey8 ? false : null;
          params[action.otherKey9] = action.otherKey9 ? false : null;
          params[action.otherKey10] = action.otherKey10 ? false : null;
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: params,
          });
        } else if (action.actionName === "IN_SEASON_RECALCULATE_IA_FORECAST") {
          const metricData = tableInfo[`${action.source}`];
          let payload = {};
          payload[`${action.dest}`] = [...metricData];
          dispatch({
            type: "TABLE_DATA",
            payload: payload,
          });
        } else if (action.actionName === "notifications") {
          addNotifications(action);
        } else if (action.actionName === "DATA_REFRESH_BASED_ON_DROPDOWN") {
          let payload = {};
          let sourceTableData = tableInfo[`${action.source_table_key}`];
          action.toggleOptions.forEach((obj) => {
            if (action["isBasedOnStore"]) {
              if (dependentInfo[`${obj}`]) {
                sourceTableData = tableInfo[`${action[`${obj}`]}`];
              }
            } else {
              if (formEditedValues[`${obj}`]) {
                sourceTableData = tableInfo[`${action[`${obj}`]}`];
              }
            }
          });
          payload[`${action.parent_table_key}`] = sourceTableData;
          dispatch({
            type: "TABLE_DATA",
            payload: payload,
          });
        } else if (action.actionName === "TAB_REDIRECT_BASED_ON_DROPDOWN") {
          if (action.path) {
            navigate(
              "/" +
                window?.location?.pathname?.split("/")[1] +
                "/" +
                window?.location?.pathname?.split("/")[2] +
                action.path
            );
          }
          if (
            action["isBasedOnDropdownKey"] &&
            formEditedValues[`${action.key}`] === action["isBasedOnDropdownKey"]
          ) {
            dispatch({
              type: "TAB_REDIRECT",
              payload: {
                activeTabIndex: action.activeTabIndex,
                parentTabIndex: action.parentTabIndex,
                activeTabValue: action.activeTabValue,
                parentTabValue: action.parentTabValue,
                isDependentOn: action.isDependentOn,
              },
            });
            setTimeout(() => {
              dispatch({
                type: "RESET_TAB_REDIRECT",
                payload: {},
              });
            }, 15000);
          }
        } else if (action.actionName === "showActiveTab") {
          dispatch({
            type: "SHOW_ACTIVE_TAB",
            payload: action,
          });
        } else if (action.actionName === "redirectNewTab") {
          dispatch({
            type: "REDIRECT_TO_NEW_TAB",
            payload: action,
          });
        } else if (action.actionName === "COPY_ONE_TABLE_TO_ANOTHER") {
          const sourceData = tableInfo[`${action.source_table_key}`];
          let payload = {};
          payload[`${action.parent_table_key}`] = [...sourceData];
          dispatch({
            type: "TABLE_DATA",
            payload: payload,
          });
          if (action["dependentOn"]) {
            let params = {};
            params[`${action.dependentOn.key}`] = true;
            params[`${action.dependentOn.otherKey}`] = false;
            dispatch({
              type: "DEPENDENT_COMPONENTS",
              payload: params,
            });
          }
          if (action.path) {
            navigate(
              "/" +
                window?.location?.pathname?.split("/")[1] +
                "/" +
                window?.location?.pathname?.split("/")[2] +
                action.path
            );
          }
          if (action.type === "tab-redirect") {
            dispatch({
              type: "TAB_REDIRECT",
              payload: {
                activeTabIndex: action.activeTabIndex,
                parentTabIndex: action.parentTabIndex,
                activeTabValue: action.activeTabValue,
                parentTabValue: action.parentTabValue,
              },
            });
          }
        } else if (action.actionName === "APPEND_NEW_DATA_TO_TABLE") {
          const sourceData = tableInfo[`${action.source_table_key}`];
          const parentData = tableInfo[`${action.parent_table_key}`];
          let payload = {};
          payload[`${action.parent_table_key}`] = [
            ...parentData,
            ...sourceData,
          ];
          dispatch({
            type: "TABLE_DATA",
            payload: payload,
          });
        } else if (action.actionName === "FILTER_DATA_BASED_ON_KEY") {
          let params = {};
          const newRow = [];
          const tableData = tableInfo[`${action.source}`];
          // const updatedData = tableData.filter(obj=> obj[`${action.mappingKey}`].includes(`(${formEditedValues[`${action.data_key}`]}%)`) )

          const updatedData = tableData.filter((obj) => {
            return (
              obj[`${action.mappingKey}`] >=
              formEditedValues[`${action.data_key}`]
            );
          });
          params[`${action.destination}`] = updatedData;
          dispatch({
            type: "TABLE_DATA",
            payload: params,
          });
        } else if (action.actionName === "RESET_DATA") {
          let params = {};
          const tableData = tableInfo[`${action.source}`];
          params[`${action.destination}`] = tableData;
          dispatch({
            type: "TABLE_DATA",
            payload: params,
          });
        } else if (action.actionName === "PAGE_OPEN_DEPENDENT_ON_KEYS") {
          let params = {};
          if (action.dependentOnClick) {
            params[`${action.dependentOnClick}`] = true;
          }
          if (action.default_table_data) {
            params[`${action.default_table_data}`] = action.default_table_data;
          }
          if (action.path) {
            navigate(
              "/" +
                window?.location?.pathname?.split("/")[1] +
                "/" +
                window?.location?.pathname?.split("/")[2] +
                action.path
            );
          }
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: params,
          });
          if (action["activeTabIndex"]) {
            dispatch({
              type: "TAB_REDIRECT",
              payload: {
                activeTabIndex: action.activeTabIndex,
                parentTabIndex: action.parentTabIndex,
                activeTabValue: action.activeTabValue,
                parentTabValue: action.parentTabValue,
                isDependentOn: action.isDependentOn,
              },
            });
            setTimeout(() => {
              dispatch({
                type: "RESET_TAB_REDIRECT",
                payload: {},
              });
            }, 15000);
          }
        } else if (
          action.actionName === "TABLE_AND_GRAPH_DATA_REFRESH_BASED_FILTER"
        ) {
          const columnsList = columnsInfo[`${action.key}`];
          let reqParams = {};
          let selectedValues = formEditedValues[`${action.mappingKey}`];
          if (selectedValues && selectedValues.length > 0) {
            action.options.forEach((colObj) => {
              if (selectedValues[0]["value"] === colObj.value) {
                columnsList.filter((col) => {
                  if (col.headerName === colObj.label) {
                    col.hide = false;
                  }
                });
              } else {
                columnsList.filter((col) => {
                  if (col.headerName === colObj.label) {
                    col.hide = true;
                  }
                });
              }
            });
            let chartInfo = tableInfo[`${action.source_graph_api_key}`];
            if (chartInfo && chartInfo.length > 0) {
              if (
                action.mappingKey &&
                formEditedValues[`${action.mappingKey}`]
              ) {
                chartInfo = chartInfo.filter(
                  (obj) =>
                    selectedValues
                      .map((selectedObj) => selectedObj.value)
                      .indexOf(obj[`${action.mappingKeyColumn}`]) !== -1
                );
              }
              if (
                action.otherMappingKey &&
                formEditedValues[`${action.otherMappingKey}`]
              ) {
                chartInfo = chartInfo.filter(
                  (obj) =>
                    formEditedValues[`${action.otherMappingKey}`]
                      .map((selectedObj) => selectedObj.value)
                      .indexOf(obj[`${action.otherMappingKeyColumn}`]) !== -1
                );
                action.otherMappingOptions.forEach((colObj) => {
                  if (
                    formEditedValues[`${action.otherMappingKey}`][0][
                      "value"
                    ] === colObj.value
                  ) {
                    columnsList.filter((col) => {
                      if (col.headerName === colObj.label) {
                        col.hide = false;
                      }
                    });
                  } else {
                    columnsList.filter((col) => {
                      if (col.headerName === colObj.label) {
                        col.hide = true;
                      }
                    });
                  }
                });
              }
              let metricValues = formEditedValues[`${action.metricKey}`].map(
                (obj) => obj.value
              );
              let xaxis = {
                categories: _.uniq(chartInfo.map((obj) => obj.xAxis)),
              };
              if (metricValues[0] === "Avg") {
                xaxis = {
                  categories: _.uniq(
                    chartInfo
                      .filter((obj) => obj.xAxis !== "Total")
                      .map((obj) => obj.xAxis)
                  ).concat(["Avg"]),
                };
              }
              let yaxis = {
                categories: _.uniq(
                  chartInfo.map((obj) => obj[`${action.otherMappingKeyColumn}`])
                ),
                tickInterval: action.tickInterval,
              };
              let groupedData = _.groupBy(chartInfo, "name");
              let chartData = [];
              let reqParams = {};
              // debugger
              if (Object.keys(groupedData).length > 0) {
                Object.keys(groupedData).forEach((groupObj) => {
                  let chartObj = { ...groupedData[`${groupObj}`][0] };
                  const totalValue = groupedData[`${groupObj}`]
                    .filter((obj) => obj.xAxis === "Total")
                    .map((obj) => parseInt(obj.value))[0];
                  chartObj["data"] = groupedData[`${groupObj}`].map(
                    (filterObj) => {
                      if (metricValues[0] === "% Column") {
                        return (parseInt(filterObj.value) / totalValue) * 100;
                      } else if (
                        metricValues[0] === "Avg" &&
                        filterObj.xAxis === "Total"
                      ) {
                        chartObj.name_copy = "Avg";
                        let allValues = groupedData[`${groupObj}`]
                          .filter((obj) => obj.xAxis !== "Total")
                          .map((obj) => parseInt(obj.value));
                        return _.sum(allValues) / allValues.length;
                      } else {
                        return parseInt(filterObj.value);
                      }
                    }
                  );
                  chartData.push({
                    name: chartObj.name,
                    color: chartObj.color,
                    data: chartObj.data,
                  });
                });
              }
              reqParams["data"] = chartData;
              chartData = chartData;
              let params = {};
              let formEditing = {};
              params[`${action.graph_api_key}`] = chartData;
              params[`${action.graph_api_key}_copy`] = [
                ...tableInfo[`${action.source_graph_api_key}`],
              ];
              formEditing[`${action.graph_api_key}_chart`] = {
                yaxis: yaxis,
                xaxis: xaxis,
              };
              dispatch({
                type: "FORM_EDITED_VALUES",
                payload: formEditing,
              });

              dispatch({
                type: "TABLE_DATA",
                payload: params,
              });
            }
            reqParams[`${action.key}`] = columnsList;
            dispatch({
              type: "COLUMN_DATA",
              payload: reqParams,
            });
          }
        } else if (action.actionName === "SET_TABLE_DATA_FOR_MODAL") {
          if (
            selectedRowInfo[`${action.source_table_key}`] &&
            selectedRowInfo[`${action.source_table_key}`].length > 0
          ) {
            const selectedRowData =
              selectedRowInfo[`${action.source_table_key}`][0];
            let rowObj = tableInfo[`${action.source_table_key}`].filter(
              (obj) => obj.id === selectedRowData.id
            )[0];
            let bulkEditData = tableInfo[
              `${action.destination_table_key}`
            ].filter((obj) => {
              if (action.conditionValues.indexOf(obj[`name`]) !== -1) {
                action.valuesUpdateOn.forEach((updateObj) => {
                  switch (obj.name) {
                    case "Min":
                      obj[`${updateObj.colName}`] = Math.round(
                        (rowObj[`min_allocation`] * updateObj.percentage) / 100
                      );
                      break;
                    case "ST%":
                      obj[`${updateObj.colName}`] = Math.round(
                        (rowObj[`st%_allocation`] * updateObj.percentage) / 100
                      );
                      break;
                    case "Allocate Quantity":
                      obj[`${updateObj.colName}`] = Math.round(
                        (rowObj[`total_allocated_units`] *
                          updateObj.percentage) /
                          100
                      );
                      break;
                    default:
                      break;
                  }
                });
              }
              return obj;
            });
            let params = {};
            params[`${action.destination_table_key}`] = bulkEditData;
            dispatch({
              type: "TABLE_DATA",
              payload: params,
            });

            if (action.otherActionName === "modal") {
              setOpen(!open);
              setItemIndex(index);
            }
          }
        } else if (action.actionName === "APPLY_PRIORITY") {
          let params = {};
          params[`${action.mappingKey}`] =
            formEditedValues[`${action.sourceKey}`];
          dispatch({
            type: "FORM_EDITED_VALUES",
            payload: params,
          });
        } else if (action.actionName === "ADD_APPLICABLE_RULES") {
          let params = {};
          params[`${action.key}`] = true;
          params[`${action.otherKey}`] = true;
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: params,
          });
          if (action.otherActionName === "modal") {
            dispatch({
              type: "FORM_EDITED_VALUES",
              payload: {
                isCloseModal: true,
              },
            });
            // setOpen(false);
            // setItemIndex(index);
          }
        } else if (action.actionName === "RESET_TABLE") {
          const gridRef = gridRefs[action.parent_table_key].current;
          gridRef.api.forEachNode((node) => node.setSelected(false));
          dispatch({
            type: "EDIT_ACTION",
            payload: { [action.parent_table_key]: false },
          });
        } else if (action.actionName === "SET_ROWS_DATA") {
          if (action?.limitToSelections) {
            if (action?.basedOnId) {
              const selectedRows = gridRefs[
                action.parent_table_key
              ]?.current.api
                .getSelectedNodes()
                .map((node) => node.data["id"]);
              if (selectedRows && selectedRows.length > 0) {
                const selectedIds = new Set(selectedRows);
                const rows = [...tableInfo[action.parent_table_key]];
                rows.forEach((row) => {
                  if (selectedIds.has(row.id)) {
                    action.data.forEach((obj) => (row[obj.field] = obj.value));
                  }
                });
                dispatch({
                  type: "TABLE_DATA",
                  payload: { [action.parent_table_key]: [...rows] },
                });
              }
            } else {
              const selectedRows = gridRefs[
                action.parent_table_key
              ]?.current.api
                .getSelectedNodes()
                .map((node) => node.rowIndex);
              if (selectedRows && selectedRows.length > 0) {
                const rows = [...tableInfo[action.parent_table_key]];
                selectedRows.forEach((rowIndex) => {
                  const row = rows[rowIndex];
                  action.data.forEach((obj) => (row[obj.field] = obj.value));
                });
                dispatch({
                  type: "TABLE_DATA",
                  payload: { [action.parent_table_key]: [...rows] },
                });
              }
            }
          } else {
            const rows = [...tableInfo[action.parent_table_key]];
            rows.forEach((row) =>
              action.data.forEach((obj) => (row[obj.field] = obj.value))
            );
            dispatch({
              type: "TABLE_DATA",
              payload: { [action.parent_table_key]: rows },
            });
          }
        } else if (action.actionName === "SAVE_TABLE") {
          if (tableInfo[action.parent_table_key]) {
            dispatch({
              type: "TABLE_DATA_SAVED",
              payload: {
                [action.parent_table_key]: _.cloneDeep(
                  tableInfo[action.parent_table_key]
                ),
              },
            });
          }
        } else if (action.actionName === "SET_HIDE_ROWS") {
          const rows = [...tableInfo[`${action.parent_table_key}`]];
          if (rows && rows.length > 0) {
            if (action?.byTag) {
              const { tag, visibility } = action.byTag;
              rows.forEach((row) => {
                if (row["#tag"] === tag)
                  row["#hidden"] = visibility ? "N" : "Y";
              });
            } else {
              action.data.forEach(
                (obj) =>
                  (rows[obj.rowIndex]["#hidden"] = obj.visibility ? "N" : "Y")
              );
            }
            dispatch({
              type: "TABLE_DATA",
              payload: { [action.parent_table_key]: [...rows] },
            });
          }
        } else if (action.actionName === "SET_OPTIONS_VISIBLE") {
          const options = new Set(dropdownInfo[action.dropdownId] || []);
          action?.info.forEach(({ id, visibility }) => {
            if (visibility) {
              options.add(id);
            } else {
              options.delete(id);
            }
          });
          dispatch({
            type: "DROPDOWN_INFO",
            payload: { [action.dropdownId]: [...options] },
          });
        } else if (action.actionName === "COPY_COLUMN_DATA_ACROSS") {
          const rowIndex = props?.otherInfo?.rowIndex || 0;
          const { source, dest } = action;
          const sourceTable = source.saved
            ? tableInfoSaved[source.table_key]
            : tableInfo[source.table_key];
          const destTable = [...tableInfo[dest.table_key]];
          if (sourceTable && sourceTable.length > 0) {
            const sourceRow = sourceTable[rowIndex];
            if (destTable && destTable.length > 0) {
              destTable.forEach((row) =>
                source.fields.forEach(
                  (field, i) => (row[dest.fields[i]] = sourceRow[field])
                )
              );
              dispatch({
                type: "TABLE_DATA",
                payload: { [dest.table_key]: [...destTable] },
              });
            }
          }
        } else if (action.actionName === "ADD_ONE_TABLE_TO_ANOTHER") {
          const sourceData = tableInfo[`${action.source_table_key}`];
          let payload = {};
          payload[`${action.parent_table_key}`] = [
            ...tableInfo[`${action.parent_table_key}`],
            ...sourceData,
          ];
          dispatch({
            type: "TABLE_DATA",
            payload: payload,
          });
          if (action["dependentOn"]) {
            let params = {};
            params[`${action.dependentOn.key}`] = true;
            params[`${action.dependentOn.otherKey}`] = false;
            dispatch({
              type: "DEPENDENT_COMPONENTS",
              payload: params,
            });
          }
          if (action.path) {
            navigate(
              "/" +
                window?.location?.pathname?.split("/")[1] +
                "/" +
                window?.location?.pathname?.split("/")[2] +
                action.path
            );
          }
        } else if (action.actionName === "DELETE_DEPENDENT_KEYS") {
          let params = { ...dependentInfo };
          action.deleteKeys.forEach((keyName) => {
            if (params[`${keyName}`]) {
              delete params[`${keyName}`];
            }
          });
          dispatch({
            type: "DELETE_DEPENDENT_COMPONENTS",
            payload: params,
          });

          if (action.path) {
            navigate(
              "/" +
                window?.location?.pathname?.split("/")[1] +
                "/" +
                window?.location?.pathname?.split("/")[2] +
                action.path
            );
          }
        } else if (action.actionName === "ADD_NEW_ROW") {
          let params = {};
          const newRow = [];
          const tableData = tableInfo[`${action.parent_table_key}`];
          if (tableData && tableData.length > 0) {
            let rowObj = { ...tableData[0] };
            rowObj[`id`] = tableData.length + 1;
            newRow.push(rowObj);
            params[`${action.parent_table_key}`] = [...tableData, ...newRow];
            dispatch({
              type: "TABLE_DATA",
              payload: params,
            });
          }
        } else if (action.actionName === "APPEND_NEW_ROW") {
          const tableData = tableInfo[action.dest_table_key];
          if (tableData && tableData.length > 0) {
            const rows = [...tableData];
            const newRow = { ...rows[rows.length - 1] };
            if (action?.defaultValue) {
              Object.keys(newRow).forEach(
                (key) => (newRow[key] = action.defaultValue)
              );
            } else if (action?.columnValues) {
              Object.keys(newRow).forEach((key) => {
                if (action.columnValues.hasOwnProperty(key))
                  newRow[key] = action.columnValues[key];
              });
            }
            rows.push(newRow);
            dispatch({
              type: "TABLE_DATA",
              payload: { [action.dest_table_key]: rows },
            });
          }
        } else if (action.actionName === "SELECTED_ROWS_ADD_TO_OTHER_TABLE") {
          if (tableInfo[`${action.parent_table_key}`]) {
            let selectedRowId = [];

            if (selectedRowInfo[`${action.parent_table_key}`]) {
              selectedRowId = selectedRowInfo[`${action.parent_table_key}`].map(
                (obj) => obj[`${action.uniqueKey}`]
              );
            }
            const insertedData = [];
            const selectedData = tableInfo[`${action.source}`];
            selectedData.filter((obj, index) => {
              if (selectedRowId.indexOf(obj[`${action.uniqueKey}`]) !== -1) {
                insertedData.push(obj);
              }
            });
            let requestData = {};
            requestData[`${action.destination_table_key}`] = [...insertedData];
            if (insertedData.length > 0) {
              dispatch({
                type: "TABLE_DATA",
                payload: requestData,
              });
            }
            if (action.path) {
              navigate(
                "/" +
                  window?.location?.pathname?.split("/")[1] +
                  "/" +
                  window?.location?.pathname?.split("/")[2] +
                  action.path
              );
            }
          } else {
            // dispatch({
            //   type: "DISPLAY_TABLE",
            //   type: "DEPENDENT_COMPONENTS",
            //   payload: params,
            // });
          }
        } else if (action.actionName === "toggle") {
          let params = {};
          params[`${action.key}`] = dependentInfo[`${action.key}`]
            ? !dependentInfo[`${action.key}`]
            : true;
          if (action?.toggleKeysList) {
            action.toggleKeysList.forEach((toggleKey) => {
              if (dependentInfo.hasOwnProperty(`${toggleKey}`)) {
                // params[`${toggleKey}`] = dependentInfo[`${toggleKey}`] ? !dependentInfo[`${toggleKey}`] : true;
                params[`${toggleKey}`] = false;
              }
            });
          }
          if (
            action.otherKey &&
            dependentInfo.hasOwnProperty(`${action.otherKey}`)
          ) {
            params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
              ? !dependentInfo[`${action.otherKey}`]
              : true;
          }
          if (action?.otherKeyEnableOnToggle) {
            action.otherKeyEnableOnToggle.forEach((toggleKey) => {
              if (dependentInfo.hasOwnProperty(`${toggleKey}`)) {
                params[`${toggleKey}`] =
                  dependentInfo[`${action.key}`] &&
                  !dependentInfo[`${toggleKey}`]
                    ? true
                    : false;
              }
            });
          }

          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: params,
          });
        } else if (action.actionName === "DELETE_ROWS") {
          if (props?.otherInfo?.params) {
            //if the action is coming from cell, then pass the row as payload
            invokeAction(action, params.data);
          }
        } else if (action.actionName === "toggleAccordionExpansion") {
          switch (action?.state) {
            case "open":
              setToggleAccordion(true);
              dispatch({
                type: "TOGGLE_ACCORDION_EXPANSION",
                payload: {
                  [action?.key]: true,
                },
              });
              break;
            case "close":
              setToggleAccordion(false);
              dispatch({
                type: "TOGGLE_ACCORDION_EXPANSION",
                payload: {
                  [action?.key]: false,
                },
              });
              break;
            default:
              setToggleAccordion((prev) => !prev);
              dispatch({
                type: "TOGGLE_ACCORDION_EXPANSION",
                payload: {
                  [action?.key]: !toggleAccordion,
                },
              });
              break;
          }
        } else if (action.actionName === "OPEN_NEW_BROWSER_TAB") {
          if (action.product === "initial-pricing") {
            switch (selectedRowInfo[action.tableName][0].id) {
              case "1": {
                window.open(action?.url1);
                break;
              }
              case "2": {
                window.open(action?.url2);
                break;
              }
              default:
                break;
            }
          } else if (action?.url) {
            window.open(action?.url);
          } else {
            const host = window?.location?.origin;
            const client = window?.location?.pathname?.split("/")[1];
            const product = window?.location?.pathname?.split("/")[2];
            const location = host + "/" + client + "/" + product + action?.path;
            window.open(location);
          }
        } else {
          if (!invokeAction(action))
            dispatch({
              type: "DISPLAY_TABLE",
              payload: action.key,
            });
        }
        flag = false;
      }
    } else if (action.actionName === "drawer") {
      setOpenDrawer(true);
    } else if (action.actionName === "tab-redirect") {
      if (action.path) {
        navigate(
          "/" +
            window?.location?.pathname?.split("/")[1] +
            "/" +
            window?.location?.pathname?.split("/")[2] +
            action.path
        );
      }
      if (action.tabHierarchyLevel) {
        dispatch({
          type: "TAB_REDIRECT",
          payload: {
            activeTabValues: action.activeTabValues,
            activeTabIndexes: action.activeTabIndexes,
            tabHierarchyLevel: action.tabHierarchyLevel,
          },
        });
      } 
      else {
        dispatch({
          type: "TAB_REDIRECT",
          payload: {
            activeTabIndex: action.activeTabIndex,
            parentTabIndex: action.parentTabIndex,
            activeTabValue: action.activeTabValue,
            parentTabValue: action.parentTabValue,
          },
        });
      }
    } else if (action.actionName === "RECALCULATE_IA_FORECAST") {
      let metricData = [];
      let payload = {};
      if (action["source_table_key"] && action["parent_table_key"]) {
        metricData = tableInfo[`${action.source_table_key}`];
        payload[`${action.parent_table_key}`] = [...metricData];
      } else {
        metricData = tableInfo["tb_plan_department_recalculate_ia_forecast"];
        payload[`tb_plan_department`] = [...metricData];
      }
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
    } else if (action.actionName === "STYLE_SHOW_HIDE") {
      let params = {};
      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      } else {
        params[`${action.otherKey}`] = true;
      }
      params[`style-save`] = false;
      params[`add-style-button`] = false;
      params[`action-bar-2`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "STYLE_SHOW_HIDE-2") {
      let params = {};
      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      } else {
        params[`${action.otherKey}`] = true;
      }
      params[`style-save-2`] = false;
      params[`add-style-button-2`] = false;
      params[`action-bar-2`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "RECOMMENDED_TABLE_SHOW_HIDE") {
      let params = {};
      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      } else {
        params[`${action.otherKey}`] = true;
      }
      params[`recommended-table-1`] = false;
      params[`recommended-table-2`] = true;
      params[`store_list_new_col`] = false;
      params[`recommended-labels`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "BUTTON_VALUE_CLICK") {
      let params = {};
      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      } else {
        params[`${action.otherKey}`] = true;
      }
      params[`store_list_new_col`] = true;
      params[`recommended-table-1`] = false;
      params[`recommended-labels`] = true;
      params[`import-new-table`] = false;
      params[`update-attributes-table`] = false;
      params[`166`] = false;
      params[`167`] = false;
      params[`update-attributes-table`] = false;
      params[`166`] = false;
      params[`initial-load`] = false;
      params[`280`] = false;
      params[`281`] = false;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "CLOSE_BUTTON_CLICK") {
      let params = {};
      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      } else {
        params[`${action.otherKey}`] = true;
      }
      params[`store_list_new_col`] = false;
      params[`recommended-table-1`] = true;
      params[`recommended-table-2`] = false;
      params[`recommended-labels`] = false;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "delete") {
      dispatch({
        type: "DELETE_SELECTED_TABLE_ROW",
        payload: true,
      });
      const tableData = tableInfo[`${action.parent_table_key}`];
      const selectedRowIndexes = new Set(
        gridRefs[action.parent_table_key].current.api
          .getSelectedNodes()
          .map((node) => node.rowIndex)
      );
      // const selectedRowIds = selectedRowInfo[`${action.parent_table_key}`].map(
      //   (obj) => obj.id
      // );
      const updatedData = tableData.filter((obj, index) => {
        return !selectedRowIndexes.has(index);
      });
      let payload = {};
      payload[`${action.parent_table_key}`] = [...updatedData];
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
    } else if (action.actionName === "edit") {
      let params = {};
      if (editActionInfo[`${action.parent_table_key}`]) {
        params[`${action.parent_table_key}`] = null;
      } else {
        params[`${action.parent_table_key}`] = true;
      }
      dispatch({
        type: "EDIT_ACTION",
        payload: params,
      });
    } else if (action.actionName === "Add") {
      let params = {};
      const newRow = [];
      const tableData = tableInfo[`${action.parent_table_key}`];
      if (tableData && tableData.length > 0) {
        let rowObj = {};
        Object.keys(tableData[0]).forEach((obj) => {
          rowObj[`${obj}`] = null;
          rowObj[`id`] = tableData.length + 1;
          rowObj[`department`] = "Skin Care";
          rowObj[`type`] = "Department";
        });
        newRow.push(rowObj);
      } else {
        newRow.push(action.defaultValue);
      }
      params[`${action.parent_table_key}`] = [...tableData, ...newRow];
      dispatch({
        type: "TABLE_DATA",
        payload: params,
      });
    } else if (action.actionName === "REFRESH_DATA") {
      let params = {};
      const newRow = [];
      const tableData = tableInfo[`${action.parent_table_key}`];
      params[`${action.source}`] = tableInfo[`${action.parent_table_key}`];
      dispatch({
        type: "TABLE_DATA",
        payload: params,
      });
    } else if (action.actionName === "displayMessage") {
      setMessage(action.message);
      setShowMessage(true);
      if (action.otherActionName === "notifications") {
        addNotifications(action);
      }
      if (action.path) {
        setTimeout(() => {
          navigate(
            "/" +
            window?.location?.pathname?.split("/")[1] +
            "/" +
            window?.location?.pathname?.split("/")[2] +
            action.path
          );
          if (action.redirect) {
            if (action.tabHierarchyLevel) {
              dispatch({
                type: "TAB_REDIRECT",
                payload: {
                  activeTabValues: action.activeTabValues,
                  activeTabIndexes: action.activeTabIndexes,
                  tabHierarchyLevel: action.tabHierarchyLevel,
                },
              });
            }
            else {
              dispatch({
                type: "TAB_REDIRECT",
                payload: {
                  activeTabIndex: action.activeTabIndex,
                  parentTabIndex: action.parentTabIndex,
                  activeTabValue: action.activeTabValue,
                  parentTabValue: action.parentTabValue,
                },
              });
            }
          }
        }, 2500)
      }
      if (action.sortBasedOnDropdown === "SORT_BASED_ON_DROPDOWN") {
        let tableParams = {};
        let tableData = [];
        if (dependentInfo[`${action.key1}`]) {
          tableData = tableInfo[`${action.parent_table_key}_${action.key1}`];
        }
        if (dependentInfo[`${action.key2}`]) {
          tableData = tableInfo[`${action.parent_table_key}_${action.key2}`];
        }
        tableParams[`${action.parent_table_key}`] = tableData;
        dispatch({
          type: "TABLE_DATA",
          payload: tableParams,
        });
      }
    } else if (action.actionName === "SHOW_CLUSTER") {
      let params = {};
      params[`showClusterLevelPLan`] = true;
      params[`dinnerware-store-cluster`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "APPLY_FILTER_ACTION") {
      let params = {};
      params[`apply-action`] = true;
      params[`default-key`] = false;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "CustomDisplayToggle") {
      let params = {};
      if (action.key === "toggle") {
        params[`default-key`] = false;
        params[`toggle`] = true;
      } else if (action.key === "default-key") {
        params[`default-key`] = true;
        params[`toggle`] = false;
      }
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "ENABLE_DISABLE_KEYS") {
      let params = {};
      params[action.key] = true;
      params[action.otherKey] = false;
      params[action.otherKey2] = action.otherKey2 ? false : null;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "ENABLE_DISABLE_BUTTON_KEYS") {
      if (action.path) {
        setTimeout(() => {
          navigate(
            "/" +
              window?.location?.pathname?.split("/")[1] +
              "/" +
              window?.location?.pathname?.split("/")[2] +
              action.path
          );
        }, 0);
      }
      let params = {};
      params[action.key] = true;
      params[action.key1] = action.key1 ? true : null;
      params[action.key2] = action.key2 ? true : null;
      params[action.key3] = action.key3 ? true : null;
      params[action.key4] = action.key4 ? true : null;
      params[action.key5] = action.key5 ? true : null;
      params[action.key6] = action.key6 ? true : null;
      params[action.otherKey1] = action.otherKey1 ? false : null;
      params[action.otherKey2] = action.otherKey2 ? false : null;
      params[action.otherKey3] = action.otherKey3 ? false : null;
      params[action.otherKey4] = action.otherKey4 ? false : null;
      params[action.otherKey5] = action.otherKey5 ? false : null;
      params[action.otherKey6] = action.otherKey6 ? false : null;
      params[action.otherKey7] = action.otherKey7 ? false : null;
      params[action.otherKey8] = action.otherKey8 ? false : null;
      params[action.otherKey9] = action.otherKey9 ? false : null;
      params[action.otherKey10] = action.otherKey10 ? false : null;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
        modalPayload: {
          isCloseModal: action.closeModal || false,
        },
      });
    } else if (action.actionName === "IN_SEASON_RECALCULATE_IA_FORECAST") {
      const metricData = tableInfo[`${action.source}`];
      let payload = {};
      payload[`${action.dest}`] = [...metricData];
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
    } else if (action.actionName === "notifications") {
      addNotifications(action);
    } else if (action.actionName === "DATA_REFRESH_BASED_ON_DROPDOWN") {
      let payload = {};
      let sourceTableData = tableInfo[`${action.source_table_key}`];
      action.toggleOptions.forEach((obj) => {
        if (action["isBasedOnStore"]) {
          if (dependentInfo[`${obj}`]) {
            sourceTableData = tableInfo[`${action[`${obj}`]}`];
          }
        } else {
          if (formEditedValues[`${obj}`]) {
            sourceTableData = tableInfo[`${action[`${obj}`]}`];
          }
        }
      });
      payload[`${action.parent_table_key}`] = sourceTableData;
      console.log("Payload details", payload);
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
    } else if (action.actionName === "TAB_REDIRECT_BASED_ON_DROPDOWN") {
      if (action.path) {
        navigate(
          "/" +
            window?.location?.pathname?.split("/")[1] +
            "/" +
            window?.location?.pathname?.split("/")[2] +
            action.path
        );
      }
      if (
        action["isBasedOnDropdownKey"] &&
        formEditedValues[`${action.key}`] === action["isBasedOnDropdownKey"]
      ) {
        dispatch({
          type: "TAB_REDIRECT",
          payload: {
            activeTabIndex: action.activeTabIndex,
            parentTabIndex: action.parentTabIndex,
            activeTabValue: action.activeTabValue,
            parentTabValue: action.parentTabValue,
            isDependentOn: action.isDependentOn,
          },
        });
        setTimeout(() => {
          dispatch({
            type: "RESET_TAB_REDIRECT",
            payload: {},
          });
        }, 15000);
      }
    } else if (action.actionName === "showActiveTab") {
      dispatch({
        type: "SHOW_ACTIVE_TAB",
        payload: action,
      });
    } else if (action.actionName === "redirectNewTab") {
      dispatch({
        type: "REDIRECT_TO_NEW_TAB",
        payload: action,
      });
    } else if (action.actionName === "COPY_ONE_TABLE_TO_ANOTHER") {
      const sourceData = tableInfo[`${action.source_table_key}`];
      let payload = {};
      payload[`${action.parent_table_key}`] = [...sourceData];
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
      if (action["dependentOn"]) {
        let params = {};
        params[`${action.dependentOn.key}`] = true;
        params[`${action.dependentOn.otherKey}`] = false;
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });
      }
      if (action.path) {
        navigate(
          "/" +
            window?.location?.pathname?.split("/")[1] +
            "/" +
            window?.location?.pathname?.split("/")[2] +
            action.path
        );
      }
      if (action.type === "tab-redirect") {
        dispatch({
          type: "TAB_REDIRECT",
          payload: {
            activeTabIndex: action.activeTabIndex,
            parentTabIndex: action.parentTabIndex,
            activeTabValue: action.activeTabValue,
            parentTabValue: action.parentTabValue,
          },
        });
      }
    } else if (action.actionName === "APPEND_NEW_DATA_TO_TABLE") {
      const sourceData = tableInfo[`${action.source_table_key}`];
      const parentData = tableInfo[`${action.parent_table_key}`];
      let payload = {};
      payload[`${action.parent_table_key}`] = [...parentData, ...sourceData];
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
    } else if (action.actionName === "FILTER_DATA_BASED_ON_KEY") {
      let params = {};
      const newRow = [];
      const tableData = tableInfo[`${action.source}`];
      console.log("Table Data", tableData);
      // const updatedData = tableData.filter(obj=> obj[`${action.mappingKey}`].includes(`(${formEditedValues[`${action.data_key}`]}%)`) )

      const updatedData = tableData.filter((obj) => {
        return (
          obj[`${action.mappingKey}`] >= formEditedValues[`${action.data_key}`]
        );
      });
      params[`${action.destination}`] = updatedData;
      dispatch({
        type: "TABLE_DATA",
        payload: params,
      });
    } else if (action.actionName === "FILTER_DATA") {
      let filterParams = {};
      const tableData = tableInfo[action.source];

      const updatedData = tableData.filter((obj) => {
        return !action?.mappingKey?.some((key, index) => {
          // Check if the object's value for the current key is in the corresponding data_key array
          return action?.data_key[index]?.includes(obj[key]);
        });
      });
      filterParams[action.source] = updatedData;

      dispatch({
        type: "TABLE_DATA",
        payload: filterParams,
      });
    } else if (action.actionName === "RESET_DATA") {
      let params = {};
      const tableData = tableInfo[`${action.source}`];
      params[`${action.destination}`] = tableData;
      dispatch({
        type: "TABLE_DATA",
        payload: params,
      });
    } else if (action.actionName === "PAGE_OPEN_DEPENDENT_ON_KEYS") {
      let params = {};
      if (action.dependentOnClick) {
        params[`${action.dependentOnClick}`] = true;
      }
      if (action.default_table_data) {
        params[`${action.default_table_data}`] = action.default_table_data;
      }
      if (action.path) {
        navigate(
          "/" +
            window?.location?.pathname?.split("/")[1] +
            "/" +
            window?.location?.pathname?.split("/")[2] +
            action.path
        );
      }
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
      if (action["activeTabIndex"]) {
        dispatch({
          type: "TAB_REDIRECT",
          payload: {
            activeTabIndex: action.activeTabIndex,
            parentTabIndex: action.parentTabIndex,
            activeTabValue: action.activeTabValue,
            parentTabValue: action.parentTabValue,
            isDependentOn: action.isDependentOn,
          },
        });
        setTimeout(() => {
          dispatch({
            type: "RESET_TAB_REDIRECT",
            payload: {},
          });
        }, 15000);
      }
    } else if (
      action.actionName === "TABLE_AND_GRAPH_DATA_REFRESH_BASED_FILTER"
    ) {
      const columnsList = columnsInfo[`${action.key}`];
      let reqParams = {};
      let selectedValues = formEditedValues[`${action.mappingKey}`];
      if (selectedValues && selectedValues.length > 0) {
        action.options.forEach((colObj) => {
          if (selectedValues[0]["value"] === colObj.value) {
            columnsList.filter((col) => {
              if (col.headerName === colObj.label) {
                col.hide = false;
              }
            });
          } else {
            columnsList.filter((col) => {
              if (col.headerName === colObj.label) {
                col.hide = true;
              }
            });
          }
        });
        let chartInfo = tableInfo[`${action.source_graph_api_key}`];
        if (chartInfo && chartInfo.length > 0) {
          if (action.mappingKey && formEditedValues[`${action.mappingKey}`]) {
            chartInfo = chartInfo.filter(
              (obj) =>
                selectedValues
                  .map((selectedObj) => selectedObj.value)
                  .indexOf(obj[`${action.mappingKeyColumn}`]) !== -1
            );
          }
          if (
            action.otherMappingKey &&
            formEditedValues[`${action.otherMappingKey}`]
          ) {
            chartInfo = chartInfo.filter(
              (obj) =>
                formEditedValues[`${action.otherMappingKey}`]
                  .map((selectedObj) => selectedObj.value)
                  .indexOf(obj[`${action.otherMappingKeyColumn}`]) !== -1
            );
            action.otherMappingOptions.forEach((colObj) => {
              if (
                formEditedValues[`${action.otherMappingKey}`][0]["value"] ===
                colObj.value
              ) {
                columnsList.filter((col) => {
                  if (col.headerName === colObj.label) {
                    col.hide = false;
                  }
                });
              } else {
                columnsList.filter((col) => {
                  if (col.headerName === colObj.label) {
                    col.hide = true;
                  }
                });
              }
            });
          }
          let metricValues = formEditedValues[`${action.metricKey}`].map(
            (obj) => obj.value
          );
          let xaxis = {
            categories: _.uniq(chartInfo.map((obj) => obj.xAxis)),
          };
          if (metricValues[0] === "Avg") {
            xaxis = {
              categories: _.uniq(
                chartInfo
                  .filter((obj) => obj.xAxis !== "Total")
                  .map((obj) => obj.xAxis)
              ).concat(["Avg"]),
            };
          }
          let yaxis = {
            categories: _.uniq(
              chartInfo.map((obj) => obj[`${action.otherMappingKeyColumn}`])
            ),
            tickInterval: action.tickInterval,
          };
          let groupedData = _.groupBy(chartInfo, "name");
          let chartData = [];
          let reqParams = {};
          // debugger
          if (Object.keys(groupedData).length > 0) {
            Object.keys(groupedData).forEach((groupObj) => {
              let chartObj = { ...groupedData[`${groupObj}`][0] };
              const totalValue = groupedData[`${groupObj}`]
                .filter((obj) => obj.xAxis === "Total")
                .map((obj) => parseInt(obj.value))[0];
              chartObj["data"] = groupedData[`${groupObj}`].map((filterObj) => {
                if (metricValues[0] === "% Column") {
                  return (parseInt(filterObj.value) / totalValue) * 100;
                } else if (
                  metricValues[0] === "Avg" &&
                  filterObj.xAxis === "Total"
                ) {
                  chartObj.name_copy = "Avg";
                  let allValues = groupedData[`${groupObj}`]
                    .filter((obj) => obj.xAxis !== "Total")
                    .map((obj) => parseInt(obj.value));
                  return _.sum(allValues) / allValues.length;
                } else {
                  return parseInt(filterObj.value);
                }
              });
              chartData.push({
                name: chartObj.name,
                color: chartObj.color,
                data: chartObj.data,
              });
            });
          }
          reqParams["data"] = chartData;
          chartData = chartData;
          let params = {};
          let formEditing = {};
          params[`${action.graph_api_key}`] = chartData;
          params[`${action.graph_api_key}_copy`] = [
            ...tableInfo[`${action.source_graph_api_key}`],
          ];
          formEditing[`${action.graph_api_key}_chart`] = {
            yaxis: yaxis,
            xaxis: xaxis,
          };
          console.log("Fomr Edit Values", formEditing, params);
          dispatch({
            type: "FORM_EDITED_VALUES",
            payload: formEditing,
          });

          dispatch({
            type: "TABLE_DATA",
            payload: params,
          });
        }
        reqParams[`${action.key}`] = columnsList;
        dispatch({
          type: "COLUMN_DATA",
          payload: reqParams,
        });
      }
    } else if (action.actionName === "SET_TABLE_DATA_FOR_MODAL") {
      if (
        selectedRowInfo[`${action.source_table_key}`] &&
        selectedRowInfo[`${action.source_table_key}`].length > 0
      ) {
        const selectedRowData =
          selectedRowInfo[`${action.source_table_key}`][0];
        let rowObj = tableInfo[`${action.source_table_key}`].filter(
          (obj) => obj.id === selectedRowData.id
        )[0];
        let bulkEditData = tableInfo[`${action.destination_table_key}`].filter(
          (obj) => {
            if (action.conditionValues.indexOf(obj[`name`]) !== -1) {
              action.valuesUpdateOn.forEach((updateObj) => {
                switch (obj.name) {
                  case "Min":
                    obj[`${updateObj.colName}`] = Math.round(
                      (rowObj[`min_allocation`] * updateObj.percentage) / 100
                    );
                    break;
                  case "ST%":
                    obj[`${updateObj.colName}`] = Math.round(
                      (rowObj[`st%_allocation`] * updateObj.percentage) / 100
                    );
                    break;
                  case "Allocate Quantity":
                    obj[`${updateObj.colName}`] = Math.round(
                      (rowObj[`total_allocated_units`] * updateObj.percentage) /
                        100
                    );
                    break;
                  default:
                    break;
                }
              });
            }
            return obj;
          }
        );
        let params = {};
        params[`${action.destination_table_key}`] = bulkEditData;
        dispatch({
          type: "TABLE_DATA",
          payload: params,
        });

        if (action.otherActionName === "modal") {
          setOpen(!open);
          setItemIndex(index);
        }
      }
    } else if (action.actionName === "APPLY_PRIORITY") {
      let params = {};
      params[`${action.mappingKey}`] = formEditedValues[`${action.sourceKey}`];
      dispatch({
        type: "FORM_EDITED_VALUES",
        payload: params,
      });
    } else if (action.actionName === "ADD_APPLICABLE_RULES") {
      let params = {};
      params[`${action.key}`] = true;
      params[`${action.otherKey}`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
      if (action.otherActionName === "modal") {
        dispatch({
          type: "FORM_EDITED_VALUES",
          payload: {
            isCloseModal: true,
          },
        });
        // setOpen(false);
        // setItemIndex(index);
      }
    } else if (action.actionName === "RESET_TABLE") {
      const gridRef = gridRefs[action.parent_table_key].current;
      gridRef.api.forEachNode((node) => node.setSelected(false));
      dispatch({
        type: "EDIT_ACTION",
        payload: { [action.parent_table_key]: false },
      });
    } else if (action.actionName === "SAVE_TABLE") {
      if (tableInfo[action.parent_table_key]) {
        dispatch({
          type: "TABLE_DATA_SAVED",
          payload: {
            [action.parent_table_key]: _.cloneDeep(
              tableInfo[action.parent_table_key]
            ),
          },
        });
      }
    } else if (action.actionName === "SET_HIDE_ROWS") {
      const rows = [...tableInfo[`${action.parent_table_key}`]];
      if (rows && rows.length > 0) {
        if (action?.byTag) {
          const { tag, visibility } = action.byTag;
          rows.forEach((row) => {
            if (row["#tag"] === tag) row["#hidden"] = visibility ? "N" : "Y";
          });
        } else {
          action.data.forEach(
            (obj) =>
              (rows[obj.rowIndex]["#hidden"] = obj.visibility ? "N" : "Y")
          );
        }
        dispatch({
          type: "TABLE_DATA",
          payload: { [action.parent_table_key]: [...rows] },
        });
      }
    } else if (action.actionName === "SET_OPTIONS_VISIBLE") {
      const options = new Set(dropdownInfo[action.dropdownId] || []);
      action?.info.forEach(({ id, visibility }) => {
        if (visibility) {
          options.add(id);
        } else {
          options.delete(id);
        }
      });
      dispatch({
        type: "DROPDOWN_INFO",
        payload: { [action.dropdownId]: [...options] },
      });
    } else if (action.actionName === "COPY_COLUMN_DATA_ACROSS") {
      const rowIndex = props?.otherInfo?.rowIndex || 0;
      const { source, dest } = action;
      const sourceTable = source.saved
        ? tableInfoSaved[source.table_key]
        : tableInfo[source.table_key];
      const destTable = [...tableInfo[dest.table_key]];
      if (sourceTable && sourceTable.length > 0) {
        const sourceRow = sourceTable[rowIndex];
        if (destTable && destTable.length > 0) {
          destTable.forEach((row) =>
            source.fields.forEach(
              (field, i) => (row[dest.fields[i]] = sourceRow[field])
            )
          );
          dispatch({
            type: "TABLE_DATA",
            payload: { [dest.table_key]: [...destTable] },
          });
        }
      }
    } else if (action.actionName === "ADD_ONE_TABLE_TO_ANOTHER") {
      const sourceData = tableInfo[`${action.source_table_key}`];
      let payload = {};
      payload[`${action.parent_table_key}`] = [
        ...tableInfo[`${action.parent_table_key}`],
        ...sourceData,
      ];
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
      if (action["dependentOn"]) {
        let params = {};
        params[`${action.dependentOn.key}`] = true;
        params[`${action.dependentOn.otherKey}`] = false;
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });
      }
      if (action.path) {
        navigate(
          "/" +
            window?.location?.pathname?.split("/")[1] +
            "/" +
            window?.location?.pathname?.split("/")[2] +
            action.path
        );
      }
    } else if (action.actionName === "DELETE_DEPENDENT_KEYS") {
      let params = { ...dependentInfo };
      action.deleteKeys.forEach((keyName) => {
        if (params[`${keyName}`]) {
          delete params[`${keyName}`];
        }
      });
      dispatch({
        type: "DELETE_DEPENDENT_COMPONENTS",
        payload: params,
      });

      if (action.path) {
        navigate(
          "/" +
            window?.location?.pathname?.split("/")[1] +
            "/" +
            window?.location?.pathname?.split("/")[2] +
            action.path
        );
      }
    } else if (action.actionName === "ADD_NEW_ROW") {
      let params = {};
      const newRow = [];
      const tableData = tableInfo[`${action.parent_table_key}`];
      if (tableData && tableData.length > 0) {
        let rowObj = { ...tableData[0] };
        rowObj[`id`] = tableData.length + 1;
        newRow.push(rowObj);
        params[`${action.parent_table_key}`] = [...tableData, ...newRow];
        dispatch({
          type: "TABLE_DATA",
          payload: params,
        });
      }
    } else if (action.actionName === "APPEND_NEW_ROW") {
      const tableData = tableInfo[action.dest_table_key];
      if (tableData && tableData.length > 0) {
        const rows = [...tableData];
        const newRow = { ...rows[rows.length - 1] };
        if (action?.defaultValue) {
          Object.keys(newRow).forEach(
            (key) => (newRow[key] = action.defaultValue)
          );
        } else if (action?.columnValues) {
          Object.keys(newRow).forEach((key) => {
            if (action.columnValues.hasOwnProperty(key))
              newRow[key] = action.columnValues[key];
          });
        }
        rows.push(newRow);
        dispatch({
          type: "TABLE_DATA",
          payload: { [action.dest_table_key]: rows },
        });
      }
    } else if (action.actionName === "SELECTED_ROWS_ADD_TO_OTHER_TABLE") {
      if (tableInfo[`${action.parent_table_key}`]) {
        let selectedRowId = [];

        if (selectedRowInfo[`${action.parent_table_key}`]) {
          selectedRowId = selectedRowInfo[`${action.parent_table_key}`].map(
            (obj) => obj[`${action.uniqueKey}`]
          );
        }
        const insertedData = [];
        const selectedData = tableInfo[`${action.source}`];
        selectedData.filter((obj, index) => {
          if (selectedRowId.indexOf(obj[`${action.uniqueKey}`]) !== -1) {
            insertedData.push(obj);
          }
        });
        let requestData = {};
        requestData[`${action.destination_table_key}`] = [...insertedData];
        if (insertedData.length > 0) {
          dispatch({
            type: "TABLE_DATA",
            payload: requestData,
          });
        }
        if (action.path) {
          navigate(
            "/" +
              window?.location?.pathname?.split("/")[1] +
              "/" +
              window?.location?.pathname?.split("/")[2] +
              action.path
          );
        }
      } else {
        // dispatch({
        //   type: "DISPLAY_TABLE",
        //   type: "DEPENDENT_COMPONENTS",
        //   payload: params,
        // });
      }
    } else if (action.actionName === "toggle") {
      let params = {};
      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action?.toggleKeysList) {
        action.toggleKeysList.forEach((toggleKey) => {
          if (dependentInfo.hasOwnProperty(`${toggleKey}`)) {
            // params[`${toggleKey}`] = dependentInfo[`${toggleKey}`] ? !dependentInfo[`${toggleKey}`] : true;
            params[`${toggleKey}`] = false;
          }
        });
      }
      if (
        action.otherKey &&
        dependentInfo.hasOwnProperty(`${action.otherKey}`)
      ) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      }
      if (action?.otherKeyEnableOnToggle) {
        action.otherKeyEnableOnToggle.forEach((toggleKey) => {
          if (dependentInfo.hasOwnProperty(`${toggleKey}`)) {
            params[`${toggleKey}`] =
              dependentInfo[`${action.key}`] && !dependentInfo[`${toggleKey}`]
                ? true
                : false;
          }
        });
      }

      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "DELETE_ROWS") {
      const tables = action?.tables || [];
      if (params || tables.length > 0) {
        //if the action is coming from cell, then pass the row as payload
        invokeAction(action, params?.data);
      }
    } else if (action.actionName === "toggleAccordionExpansion") {
      switch (action?.state) {
        case "open":
          setToggleAccordion(true);
          dispatch({
            type: "TOGGLE_ACCORDION_EXPANSION",
            payload: {
              [action?.key]: true,
            },
          });
          break;
        case "close":
          setToggleAccordion(false);
          dispatch({
            type: "TOGGLE_ACCORDION_EXPANSION",
            payload: {
              [action?.key]: false,
            },
          });
          break;
        default:
          setToggleAccordion((prev) => !prev);
          dispatch({
            type: "TOGGLE_ACCORDION_EXPANSION",
            payload: {
              [action?.key]: !toggleAccordion,
            },
          });
          break;
      }
    } else if (action.actionName === "OPEN_NEW_BROWSER_TAB") {
      if (action.product === "initial-pricing") {
        switch (selectedRowInfo[action.tableName][0].id) {
          case "1": {
            window.open(action?.url1);
            break;
          }
          case "2": {
            window.open(action?.url2);
            break;
          }
          default:
            break;
        }
      } else if (action?.url) {
        window.open(action?.url);
      } else {
        const host = window?.location?.origin;
        const client = window?.location?.pathname?.split("/")[1];
        const product = window?.location?.pathname?.split("/")[2];
        const location = host + "/" + client + "/" + product + action?.path;
        window.open(location);
      }
    } else if (action.actionName === "CLICK_WITH_MESSAGE") {
      let params = {};

      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      } else {
        // if(action.otherKey){
        params[`${action.otherKey}`] = true;
        // }
      }
      if (action.hasOwnProperty("defaultToggle")) {
        params[`${action.key}`] = action.defaultToggle;
      }
      setMessage(action.message);
      setShowMessage(true);
      setAction(action);
      setTimeout(() => {
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });
      }, action.delay || 1500);
    } else if (action.actionName === "UPDATE_TABLE_DATA") {
      const sourceData = tableInfo[`${action.source_table_key}`];
      let payload = {};
      payload[`${action.parent_table_key}`] = sourceData;
      dispatch({
        type: "TABLE_DATA",
        payload: payload,
      });
    } else if (action.actionName === "SINGLE_SELECT") {
      if (
        checkedRows[action.selectionKey] &&
        checkedRows[action.selectionKey].length === 1
      ) {
        let params = {};
        params[action.singleSelect] = true;
        params["default-key"] = false;
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });
      }
      navigate(
        "/" +
          window?.location?.pathname?.split("/")[1] +
          "/" +
          window?.location?.pathname?.split("/")[2] +
          action.path
      );
    } else if (action.actionName === "ClearSelectedRowInfo") {
      dispatch({
        type: "CHECKED_ROWS",
        payload: {
          [action?.parent_table_key]: false,
        },
      });
      dispatch({
        type: "SELECTED_ROWS",
        payload: {
          [action?.parent_table_key]: false,
        },
      });
    } else if (action?.actionName === "downloadTable") {
      dispatch({
        type: "EXPORT_TABLE",
        payload: {
          isExportTable: true,
          key: action?.key,
        },
      });
    } else if (action?.actionName === "deleteTab") {
      let tabIndex = 0;
      let updatedTabs = tabList[`${action?.unique_tab_key}`]?.filter(
        (tab, idx) => {
          if (tab?.value === action?.tabName) {
            tabIndex = idx;
          }
          return tab?.value !== action?.tabName;
        }
      );
      if (tabIndex - 1 >= 0) {
        tabIndex = tabIndex - 1;
      }
      dispatch({
        type: "UPDATE_TABLIST",
        payload: {
          [`${action?.unique_tab_key}`]: [...updatedTabs],
        },
      });
      dispatch({
        type: "TAB_REDIRECT",
        payload: {
          parentTabValue: `${updatedTabs?.[tabIndex]?.value}`,
        },
      });
      let params = {};
      if (action?.keys) {
        action?.keys.forEach((key) => {
          params[`${key}`] = true;
        });
      }
      if (action?.otherKeys) {
        action?.otherKeys.forEach((key) => {
          params[`${key}`] = false;
        });
      }
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action?.actionName === "restoreTab") {
      let tabIndex = -1;
      let restoredTab = allTabList[`${action?.unique_tab_key}`]?.filter(
        (tab, idx) => {
          tabIndex = idx;
          return tab?.value === action?.tabName;
        }
      );
      dispatch({
        type: "UPDATE_TABLIST",
        payload: {
          [`${action?.unique_tab_key}`]: [
            ...tabList[`${action?.unique_tab_key}`],
            ...restoredTab,
          ],
        },
      });
      if (tabIndex !== -1) {
        dispatch({
          type: "TAB_REDIRECT",
          payload: {
            parentTabValue: `${restoredTab?.[0]?.value}`,
          },
        });
      }
      let params = {};
      if (action?.keys) {
        action?.keys.forEach((key) => {
          params[`${key}`] = true;
        });
      }
      if (action?.otherKeys) {
        action?.otherKeys.forEach((key) => {
          params[`${key}`] = false;
        });
      }
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action?.actionName === "resetTabs") {
      let params = {};
      if (action?.keys) {
        action?.keys.forEach((key) => {
          params[`${key}`] = true;
        });
      }
      if (action?.otherKeys) {
        action?.otherKeys.forEach((key) => {
          params[`${key}`] = false;
        });
      }
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
      dispatch({
        type: "RESET_TABLIST",
        payload: {
          [`${action?.unique_tab_key}`]: [
            ...allTabList[`${action?.unique_tab_key}`],
          ],
        },
      });
      dispatch({
        type: "TAB_REDIRECT",
        payload: {
          parentTabValue: `${
            allTabList[`${action?.unique_tab_key}`]?.[0]?.value
          }`,
        },
      });
    } else if (action.actionName === "STORE_TO_BUFFER") {
      const bufferObj = {};
      action.Id.map((id, i) => {
        const field = action.sourceColumns[i];
        const data = params.node.group
          ? params.node.allLeafChildren[0].data
          : params.data;
        bufferObj[id] = data[field];
      });
      dispatch({
        type: "ADD_TO_BUFFER",
        payload: bufferObj,
      });
    } else {
      if (!invokeAction(action))
        dispatch({
          type: "DISPLAY_TABLE",
          payload: action.key,
        });
    }
  };

  const onIconClickAction = (item, index) => {
    const { action } = item;
    const { parent_table_key, unique_key, add_values } = item.action;
    let params = {};
    if (action.actionName === "click") {
      if (editActionInfo[`${item.parent_table_key}`]) {
        params[`${item.parent_table_key}`] = null;
      } else {
        params[`${item.parent_table_key}`] = true;
      }
      dispatch({
        type: "EDIT_ACTION",
        payload: params,
      });
    } else if (action.actionName === "navigate") {
      navigate(
        "/" +
          window?.location?.pathname?.split("/")[1] +
          "/" +
          window?.location?.pathname?.split("/")[2] +
          action.path
      );
    } else if (action.actionName === "AddNewRowBasedOnEdit") {
      if (tableInfo[`${parent_table_key}`]) {
        let selectedRowId = null;

        if (selectedRowInfo[`${parent_table_key}`]) {
          selectedRowId = selectedRowInfo[`${parent_table_key}`].map(
            (obj) => obj[`${unique_key}`]
          );
        }
        const insertedData = [];
        const selectedData = selectedRowInfo[`${parent_table_key}`]
          ? selectedRowInfo[`${parent_table_key}`]
          : tableInfo[`${parent_table_key}`];
        selectedData.filter((obj, index) => {
          if (index === 0) {
            let params = {};
            Object.keys(obj).forEach((nestedObj) => {
              params[`${nestedObj}`] = null;
            });
            params[`${unique_key}`] = obj[`${unique_key}`];
            insertedData.push({ ...params, ...add_values });
          }
        });
        let requestData = {};
        requestData[`${parent_table_key}`] = [
          ...tableInfo[`${parent_table_key}`],
          ...insertedData,
        ];
        dispatch({
          type: "TABLE_DATA",
          payload: requestData,
        });
      }
    } else if (action.actionName === "modal") {
      setOpen(!open);
      setItemIndex(index);
    } else if (action.actionName === "toggle") {
      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;

      if (
        action.otherKey &&
        dependentInfo.hasOwnProperty(`${action.otherKey}`)
      ) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      }
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "ENABLE_DISABLE_BUTTON_KEYS") {
      if (action.path) {
        setTimeout(() => {
          navigate(
            "/" +
              window?.location?.pathname?.split("/")[1] +
              "/" +
              window?.location?.pathname?.split("/")[2] +
              action.path
          );
        }, 0);
      }
      let params = {};
      params[action.key] = true;
      params[action.key1] = action.key1 ? true : null;
      params[action.key2] = action.key2 ? true : null;
      params[action.key3] = action.key3 ? true : null;
      params[action.key4] = action.key4 ? true : null;
      params[action.key5] = action.key5 ? true : null;
      params[action.key6] = action.key6 ? true : null;
      params[action.otherKey1] = action.otherKey1 ? false : null;
      params[action.otherKey2] = action.otherKey2 ? false : null;
      params[action.otherKey3] = action.otherKey3 ? false : null;
      params[action.otherKey4] = action.otherKey4 ? false : null;
      params[action.otherKey5] = action.otherKey5 ? false : null;
      params[action.otherKey6] = action.otherKey6 ? false : null;
      params[action.otherKey7] = action.otherKey7 ? false : null;
      params[action.otherKey8] = action.otherKey8 ? false : null;
      params[action.otherKey9] = action.otherKey9 ? false : null;
      params[action.otherKey10] = action.otherKey10 ? false : null;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
        modalPayload: {
          isCloseModal: action.closeModal || false,
        },
      });
    } else {
      invokeAction(action);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getIcon = (item, index) => {
    return getButtonIcon(
      item,
      index,
      selectedRowInfo,
      editActionInfo,
      onAction,
      onIconClickAction
    );
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleToggle = (e, item) => {
    const { name, value } = e.target;
    switch (item.type) {
      case "UPDATE_CURRENT_ROW":
        if (tableInfo[`${item.parent_table_key}`]) {
          let selectedRowId = props.otherInfo.data[`${item.unique_key}`];

          const updatedData = tableInfo[`${item.parent_table_key}`].filter(
            (obj) => {
              if (obj[`${item.unique_key}`] == selectedRowId) {
                if (obj["include_unmapped_products"] === "TRUE") {
                  obj[`products_mapped`] = obj[`old_products_mapped`];
                } else {
                  obj[`old_products_mapped`] = obj[`products_mapped`];
                  obj[`products_mapped`] = "100/100";
                }
                obj["include_unmapped_products"] =
                  obj["include_unmapped_products"] === "TRUE"
                    ? "FALSE"
                    : "TRUE";
                return obj;
              }
              return obj;
            }
          );
          let params = {};
          params[`${item.parent_table_key}`] = [...updatedData];
          dispatch({
            type: "TABLE_DATA",
            payload: params,
          });
        }

        break;
      case "UPDATE_CURRENT_ROW_VALUE":
        if (
          tableInfo[`${item.parent_table_key}`] &&
          tableInfo[`${item.child_table_key}`]
        ) {
          let selectedRowId = props.otherInfo.data[`${item.unique_key}`];
          const childTable = tableInfo[`${item.child_table_key}`].filter(
            (obj) => {
              if (obj[`${item.unique_key}`] == selectedRowId) {
                obj["mapping"] = obj["mapping"] === "TRUE" ? "FALSE" : "TRUE";
                return obj;
              }
              return obj;
            }
          );
          let params = {};
          params[`${item.child_table_key}`] = [...childTable];
          // dispatch({
          //   type: "TABLE_DATA",
          //   payload: params,
          //   });
        }
      default:
        break;
    }
  };

  function dynamicAction(action) {
    let payload = null;
    switch (action.payloadType) {
      case "row":
        payload = params.node;
        break;
      default:
        break;
    }
    invokeAction(action, payload);
  }
  const getActionType = (item, index) => {
    switch (props.otherInfo.actionType) {
      case "link":
        return (
          <a
            href="javascript:void(0)"
            onClick={() => onAction(item.action, index)}
            style={{ textDecoration: "none", color: "#0055af" }}
          >
            {props.otherInfo.isLabelAction}
          </a>
        );
        break;
      case "toggle":
        return (
          <>
            <Switch
              {...label}
              checked={props.otherInfo.isLabelAction === "TRUE"}
              onClick={(e) => {
                handleToggle(e, item);
              }}
            />
          </>
        );
        break;
      case "switch":
        return (
          <>
            {props.label}
            <FormControlLabel control={<Switch />} label={props.endLabel} />
          </>
        );
        break;
      case "text_and_icon_click":
        return (
          <>
            <span>
              {" "}
              <span>{props.otherInfo.isLabelAction} </span>&nbsp;&nbsp;{" "}
              <span>
                <a
                  href="javascript:void(0)"
                  onClick={() => onAction(item.action, index)}
                  style={{ textDecoration: "none", ...props.otherInfo.style }}
                >
                  {getIcon(props.otherInfo, 0)}
                </a>
              </span>
            </span>
          </>
        );
        break;
      default:
        return (
          <>
            {["Copy"].indexOf(props.otherInfo.isLabelAction) !== -1 ? (
              <>
                {props.data.details?.map((item, index) => (
                  <>
                    {/* {renderIcons(item)} */}
                    <EditIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        onAction(item.navigateAction, index);
                      }}
                    />{" "}
                    <ContentCopyIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        onAction(item.action, index);
                      }}
                    />{" "}
                    {item.action["isNoteIconNotRequired"] ? null : (
                      <>
                        {" "}
                        <DescriptionIcon sx={{ cursor: "pointer" }} />{" "}
                      </>
                    )}{" "}
                  </>
                ))}
              </>
            ) : (
              <>
                {props.otherInfo.type === "Button" ? (
                  <Button
                    variant={
                      item.varient === "contained"
                        ? "primary"
                        : item.varient === "text"
                        ? "url"
                        : "secondary"
                    }
                    sx={{ ...props.otherInfo.style }}
                    onClick={() => onAction(item.action, index)}
                    size="normal"
                  >
                    {props.otherInfo.isLabelAction}
                  </Button>
                ) : (
                  <Button
                    href="javascript:void(0)"
                    onClick={() => {
                      let itemAction = item?.action;
                      if (
                        item?.cellActions &&
                        Number.isSafeInteger(cellActionIndex)
                      ) {
                        itemAction = item.cellActions[cellActionIndex];
                      }
                      if (itemAction) {
                        if (Array.isArray(itemAction)) {
                          itemAction.forEach((obj) => {
                            if (obj.dynamicPayload) {
                              dynamicAction(obj);
                            } else {
                              onAction(obj, index);
                            }
                          });
                        } else {
                          if (itemAction.dynamicPayload) {
                            dynamicAction(itemAction);
                          }
                          onAction(itemAction, index);
                        }
                      }
                    }}
                    variant={item.varient}
                    // style={
                    //   params &&
                    //   params.data[`#disable_${params.colDef.field}`] === "Y"
                    //     ? disableLinkStyle
                    //     : { textDecoration: "none", color:'#0055af'}
                    // }
                    style={item.style}
                  >
                    {props.otherInfo.isLabelAction}
                  </Button>
                )}
              </>
            )}
          </>
        );
        break;
    }
  };

  const handleMessageClose = () => {
    setShowMessage(false);
  };
  useEffect(() => {
    if (data?.clearDependentsOnDestroy && data?.dependentOn) {
      return () => {
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: {
            [data.dependentOn]: false,
          },
        });
      };
    }
  }, []);
  const getModalData = (item) => {
    if (item?.cellActions) {
      return item.cellActions[cellActionIndex];
    }
    if (Array.isArray(item["action"]))
      return item["action"][item["action"].length - 1];
    return item["action"];
  };
  return (
    <>
      {openDrawer ? (
        <CustomDrawer
          info={data.details[0].action.info}
          openDrawer={true}
          setOpenDrawer={setOpenDrawer}
        />
      ) : null}
      {open ? (
        <>
          <CustomModal
            show={open}
            {...getModalData(data.details[itemIndex])}
            // handleClose={handleClose}
          />
        </>
      ) : null}

      {showMessage && (
        <SnackBar
          message={message}
          variant={data?.details?.[0]?.["action"]?.["variant"] || "success"}
          handleClose={handleMessageClose}
        />
      )}
      <Grid>
        <div
          style={{
            justifyContent:
              data && data.alignment == "left"
                ? "flex-start"
                : data.alignment == "center"
                ? "center"
                : "flex-end",
            ...data.style,
            display: "flex",
            gap: "10px",
          }}
        >
          {data.buttonGroup ? (
            <GroupOfButton
              details={data.details}
              onAction={onAction}
              getIcon={getIcon}
              dependentInfo={dependentInfo}
              defaultActiveIndex={data?.defaultActiveIndex || 0}
            />
          ) : (
            data.details?.map((item, index) => (
              <>
                {props.otherInfo && props.otherInfo.isLabelAction ? (
                  <>{getActionType(item, index)}</>
                ) : (
                  <>
                    {item.isIcon ? (
                      <>
                        {item?.isIAIconButton ? (
                          <>
                            {getIcon(item, index) !== null ? (
                              <Button
                                style={{
                                  // textTransform: "none",
                                  // margin: 5,
                                  ...(item.style || {}),
                                }}
                                variant={
                                  item.varient === "contained"
                                    ? "primary"
                                    : item.varient === "text"
                                    ? "url"
                                    : "secondary"
                                }
                                size={
                                  !item.size || item.size == "medium"
                                    ? "normal"
                                    : "small"
                                }
                                title={item.name}
                                disabled={
                                  item?.disabledBy
                                    ? item?.disabledBy
                                      ? !dependentInfo[`${item["disabledBy"]}`]
                                      : false
                                    : item.disable || false
                                }
                                onClick={() => {
                                  if (Array.isArray(item.action)) {
                                    item.action.forEach((action) =>
                                      onAction(action, index)
                                    );
                                  } else {
                                    onAction(item.action, index);
                                  }
                                }}
                                icon={() => getIAIcon(item, index)}
                                className={item.className || "ia-icon-button"}
                              >
                                {item.iconLabel}
                              </Button>
                            ) : null}
                          </>
                        ) : (
                          <>
                            {item.parent_table_key ? (
                              <>
                                {selectedRowInfo[`${item.parent_table_key}`] &&
                                  getIcon(item, index)}
                              </>
                            ) : (
                              <>
                                {getIcon(item, index) !== null ? (
                                  <Button
                                    style={{
                                      // textTransform: "none",
                                      // margin: 5,
                                      ...(item.style || {}),
                                    }}
                                    variant={
                                      item.varient === "contained"
                                        ? "primary"
                                        : item.varient === "text"
                                        ? "url"
                                        : "secondary"
                                    }
                                    size={
                                      !item.size || item.size == "medium"
                                        ? "normal"
                                        : item.size
                                    }
                                    title={item.name}
                                    disabled={
                                      item?.disabledBy
                                        ? item?.disabledBy
                                          ? !dependentInfo[
                                              `${item["disabledBy"]}`
                                            ]
                                          : false
                                        : item.disable || false
                                    }
                                    onClick={() => {
                                      if (Array.isArray(item?.action)) {
                                        item?.action?.forEach((action) =>
                                          onAction(action, index)
                                        );
                                      } else {
                                        onAction(item?.action, index);
                                      }
                                    }}
                                    icon={() => getIcon(item, index)}
                                    className={item.className}
                                  >
                                    {item.iconLabel}
                                  </Button>
                                ) : null}
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {item["isDisabledBasedOnRowSelection"] ||
                        item["isDisabledBasedOnOther"] ? (
                          <Button
                            //   style={{
                            //     textTransform: "none",
                            //     margin: 5,
                            //     ...item.style,
                            //   }}
                            variant={
                              item.varient === "contained"
                                ? "primary"
                                : item.varient === "text"
                                ? "url"
                                : "secondary"
                            }
                            size={
                              !item.size || item.size == "medium"
                                ? "normal"
                                : "small"
                            }
                            title={item.name}
                            // disabled={obj.disableFeat && diable}
                            onClick={() => {
                              if (Array.isArray(item.action)) {
                                item.action.forEach((action) =>
                                  onAction(action, index)
                                );
                              } else {
                                onAction(item.action, index);
                              }
                            }}
                            disabled={
                              (item.isDisabledBasedOnRowSelection
                                ? selectedRowInfo[
                                    `${item.isDisabledBasedOnRowSelection}`
                                  ] &&
                                  selectedRowInfo[
                                    `${item.isDisabledBasedOnRowSelection}`
                                  ].length > (item.selectedRowscount || 0)
                                  ? false
                                  : true
                                : false) ||
                              (item.isDisabledBasedOnOther &&
                                !dependentInfo[
                                  `${item.isDisabledBasedOnOther}`
                                ])
                            }
                          >
                            {item.name}
                          </Button>
                        ) : (
                          <>
                            {item.type === "download" ? (
                              <DownloadFiles {...item} />
                            ) : (
                              <>
                                {item[`dependentOn`] ? (
                                  item.iconType ? (
                                    <ButtonDependent
                                      item={item}
                                      getIcon={getIcon}
                                      onAction={onAction}
                                      index={index}
                                      dependentInfo={dependentInfo}
                                    />
                                  ) : (
                                    <ButtonDependentWithoutIcon
                                      item={item}
                                      onAction={onAction}
                                      index={index}
                                      dependentInfo={dependentInfo}
                                    />
                                  )
                                ) : // most common button used
                                item.iconType ? (
                                  item.name ? (
                                    <ButtonWithIconLabel
                                      item={item}
                                      getIcon={getIcon}
                                      onAction={onAction}
                                      index={index}
                                      dependentInfo={dependentInfo}
                                    />
                                  ) : (
                                    <ButtonWithoutLabel
                                      item={item}
                                      getIcon={getIcon}
                                      onAction={onAction}
                                      index={index}
                                      dependentInfo={dependentInfo}
                                    />
                                  )
                                ) : (
                                  <ButtonWithoutIcon
                                    item={item}
                                    onAction={onAction}
                                    index={index}
                                    dependentInfo={dependentInfo}
                                  />
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ))
          )}
        </div>
      </Grid>
    </>
  );
};

export default CustomActionButton;
