import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import useAction from "../../../utils/useAction";

const alignment = {
  left: { marginLeft: 0 },
  right: { marginLeft: "auto" },
  center: {
    marginLeft: "auto",
    marginRight: "auto",
  },
};
function ProgressBar(props) {
  const { data } = props;
  const { duration = 5000, actionOnEnd } = data;
  const [fill, setFill] = useState(0);
  const invokeAction = useAction();
  const props_primary = {
    variant: "determinate",
    value: fill,
    thickness: data.details.thickness,
    size: data.details.size,
    sx: { color: data.details.color_id },
  };
  const props_secondary = {
    ...props_primary,
    value: 100,
    sx: { color: "grey", opacity: 0.1 },
  };
  useEffect(() => {
    const interval = 100;
    const increment = (interval / duration) * 100;
    const timer = setInterval(() => {
      setFill((prev) => {
        const nextValue = prev + increment;
        if (nextValue > 100) {
          clearInterval(timer);
          eventOnEnd();
          return 100;
        }
        return nextValue;
      });
    }, interval);
    return () => clearInterval(timer);
  }, [duration]);
  function eventOnEnd() {
    if (actionOnEnd) {
      invokeAction(actionOnEnd);
    }
  }
  return (
    <div className="progress" style={alignment[data.details.alignment]}>
      <div
        style={{
          position: "relative",
          ...data?.details?.wrapperStyle,
        }}
      >
        <p style={{ ...data?.details?.centralTextStyle }}>{`${fill}%`}</p>
        <CircularProgress {...props_primary} />
        <CircularProgress className="secondary-bar" {...props_secondary} />
      </div>
      <p className="progress--text">{data.details.text}</p>
    </div>
  );
}
export default ProgressBar;
