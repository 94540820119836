import React, { useEffect, useState,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ComponentResolver from "../Resolver/ComponentResolver";
import { addNotifications } from "../data";
import CustomActionButton from "./CustomActionButton";
import { deleteData } from "../action";
import useAction from "../../../utils/useAction";

// Material Ui
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LoadingOverlay from "../../../componentsV2/LoadingOverlay";


// Custom Components
import SnackBar from "../../../componentsV2/SnackBar";

// Impact Ui
import { Modal } from "impact-ui";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const CustomModal = (props) => {
  const { show, type, title, components, setOpenAssort, footer_actions, width='lg' } = props;
  const [showMessage, setShowMessage] = useState(false);
  const {
    selectedRowInfo,
    tableInfo,
    formEditedValues,
    dependentInfo,
    showActiveTab,
    newDynamicTabIndexValue,
    tableValues,
    dropdownSelectionData,
    modalRefs,
    gridRefs
  } = useSelector((state) => state.home);
  const { formMasterValuesData } = useSelector((state) => state.filter);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const invokeAction = useAction()
  const [open, setOpen] = useState(props.show);
  const modalRef = useRef();
  useEffect(() => {
    setOpen(props.show);
  }, [props.show]);

  useEffect(() => {
    if (
      Object.keys(formEditedValues).length > 0 &&
      formEditedValues.isCloseModal
    ) {
      setOpen(false);
      dispatch({
        type: "FORM_EDITED_VALUES",
        payload: {
          isCloseModal: false,
        },
      });
    }
  }, [formEditedValues]);

  const updateActionOnModalClose = (item) => {
    switch (item.type) {
      case "UPDATE_PARENT_TABLE":
        if (tableInfo[`${item.parent_table_key}`]) {
          let uniqueKey = null;

          if (selectedRowInfo[`${item.parent_table_key}`]) {
            uniqueKey = selectedRowInfo[`${item.parent_table_key}`].map(
              (obj) => obj[`${item.unique_key}`]
            )[0];
          }
          const updatedData = tableInfo[`${item.parent_table_key}`].filter(
            (obj) => {
              if (obj[`${item.unique_key}`] == uniqueKey) {
                item.updating_keys.forEach((updateObj) => {
                  obj[`${updateObj}`] = 1;
                });
                return obj;
              }
              return obj;
            }
          );
          let params = {};
          params[`${item.parent_table_key}`] = [...updatedData];
          dispatch({
            type: "TABLE_DATA",
            payload: params,
          });
        }
        break;
      case "UPDATE_EDITED_VALUES_IN_PARENT_TABLE":
        if (tableInfo[`${item.parent_table_key}`]) {
          let selectedRowIds = [];

          if (selectedRowInfo[`${item.parent_table_key}`]) {
            selectedRowIds = selectedRowInfo[`${item.parent_table_key}`].map(
              (obj) => obj[`${item.unique_key}`]
            );
          }
          const updatedData = tableInfo[`${item.parent_table_key}`].filter(
            (obj) => {
              if (selectedRowIds.includes(obj[`${item.unique_key}`])) {
                item.updating_keys.forEach((updateObj) => {
                  if (item.isMappingKeys) {
                    if (
                      formEditedValues[
                        `${item.updateMappingKeys[`${updateObj}`]}`
                      ]
                    ) {
                      obj[`${updateObj}`] =
                        formEditedValues[
                          `${item.updateMappingKeys[`${updateObj}`]}`
                        ];
                    }
                  } else {
                    obj[`${updateObj}`] = formEditedValues[`${updateObj}`];
                  }
                });
                return obj;
              }
              return obj;
            }
          );
          let params = {};
          params[`${item.parent_table_key}`] = [...updatedData];
          dispatch({
            type: "TABLE_DATA",
            payload: params,
          });
        }
        break;
      case "UPDATE_PARENT_SKU_TABLE":
					if (tableInfo[`${item.parent_table_key}`]) {
						const parentData = tableInfo[`${item.parent_table_key}`]
						const sourceData = tableInfo[`${item.source_table_key}`].map(obj=> (obj.sku_id || obj.default_selected_value) )
            if(sourceData){
              // sourceData.filter(obj=>{
                let dataObj = { ...parentData[0], "like_style": sourceData.join(","),sku_id: tableInfo[`${item.source_table_key}`][0]["new_sku_id"] }
                parentData.push(dataObj)
              // })
              let params = {};
              params[`${item.parent_table_key}`] = [...parentData];
              dispatch({
                type: "TABLE_DATA",
                payload: params,
              });
            }
						
					}
        case "UPDATE_STORE_PLAN":
            if (tableInfo[`${item.parent_table_key}`]) {
              const parentData = tableInfo[`${item.parent_table_key}`]
              const sourceData = tableInfo[`${item.source_table_key}`]
              if(sourceData){
                sourceData.filter(obj=>{
                  let dataObj = { ...parentData[0],
                     "store_type": (obj["default_selected_value_store_type"] ? obj["default_selected_value_store_type"] : obj.store_type.split(",")[0] ),
                  "cluster": (obj["default_selected_value_cluster"] ? `Cluster ${obj["default_selected_value_cluster"]}`  : `Cluster ${obj.cluster.split(",")[0]}` ) ,
                  "store": obj.store,
                  ly:0,	ly_pen:0,	ia_fcst:0,	ia_fcst_pen:0,	wp:0,	wp_pen:0,	var_ly:0,	var_iaf:0,
                   isEyeIconRequired: true,hide:"" }
                  parentData.push(dataObj)
                })
                let params = {};
                params[`${item.parent_table_key}`] = [...parentData];
                dispatch({
                  type: "TABLE_DATA",
                  payload: params,
                });
              }
              
            }
					break;
      default:
        break;
    }
  };

  const onAction = async (item, index) => {
    if (item) {
      if (item && item.type) {
        updateActionOnModalClose(item);
      }
      if (item && item.actionName === "displayMessage") {
        setShowMessage(true);
        if (item.otherActionName === "notifications") {
          addNotifications(item);
        }
        if (
          item["sortBasedOnDropdown"] &&
          item["sortBasedOnDropdown"] === "SORT_BASED_ON_DROPDOWN"
        ) {
          let tableParams = {};
          let tableData = [];
          if (dependentInfo[`${item.key1}`]) {
            tableData = tableInfo[`${item.parent_table_key}_${item.key1}`];
          }
          if (dependentInfo[`${item.key2}`]) {
            tableData = tableInfo[`${item.parent_table_key}_${item.key2}`];
          }
          tableParams[`${item.parent_table_key}`] = tableData;
          dispatch({
            type: "TABLE_DATA",
            payload: tableParams,
          });
        }
        if (item["navigationPath"]) {
          navigate(
            "/" +
              window?.location?.pathname?.split("/")[1] +
              "/" +
              window?.location?.pathname?.split("/")[2] +
              item.navigationPath
          );
        }
      }
      if (item && item.actionName === "navigate") {
        if (item.dependentOn) {
          let params = {};
          params[`${item.dependentOn}`] = true;
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: params,
          });
        }
        navigate(
          "/" +
            window?.location?.pathname?.split("/")[1] +
            "/" +
            window?.location?.pathname?.split("/")[2] +
            item.path
        );
      }
      if (item.actionName === "tab-redirect") {
        if (item.path) {
          navigate(
            "/" +
              window?.location?.pathname?.split("/")[1] +
              "/" +
              window?.location?.pathname?.split("/")[2] +
              item.path
          );
        }
        dispatch({
          type: "TAB_REDIRECT",
          payload: {
            activeTabIndex: item.activeTabIndex,
            parentTabIndex: item.parentTabIndex,
            activeTabValue: item.activeTabValue,
            parentTabValue: item.parentTabValue,
          },
        });
      }
      if (item.actionName === "ENABLE_DISABLE_KEYS") {
        let params = {};
        params[item.key] = true;
        params[item.otherKey] = false;
        params[item.otherKey2]=item.otherKey2 ?   false:null;
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });
        setOpen(false);
      }
      
      if (item.type === "ADD_BRANDS_TO_DROPDOWN") {
        if (tableInfo[`${item.parent_table_key}`]) {
          const formValues = [];
          tableInfo[`${item.parent_table_key}`].filter((obj, index) => {
            let brandObj = {
              id: index,
              name: obj.placeholder,
              parent_type_id: item.filter_id,
              parent_id: item.filter_id,
              "Screen Name": null,
              order_value: null,
              infotip: null,
              infotip_enabled: null,
              is_default_value: null,
              is_active: null,
              isNewBrand: true,
              department: obj.department,
            };
            formValues.push(brandObj);
          });

          dispatch({
            type: "ADD_FORM_MASTER_VALUES",
            payload: [...formValues],
          });
          let placeholderParams = {};
          placeholderParams[`${item.parent_table_key}`] = [];
          dispatch({
            type: "TABLE_DATA",
            payload: placeholderParams,
          });
        }
      } else if (item.type === "RESET_BRANDS_TO_DROPDOWN") {
        const removeNewBrands = formMasterValuesData.filter(
          (obj) => !obj.isNewBrand
        );

        setOpen(false);
      } else if (item.type === "UPDATE_MASTER_PLAN") {
        let reqParams = {};
        reqParams[`${item.filter_id}`] = false;
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: reqParams,
        });
        let placeholderParams = {};
        placeholderParams[`${item.source}`] = tableInfo[`${item.key}`];
        dispatch({
          type: "TABLE_DATA",
          payload: placeholderParams,
        });
        setOpen(false);
      } else if (item.actionName === "click") {
        let params = {};
        params[`${item.key}`] = dependentInfo[`${item.key}`]
          ? !dependentInfo[`${item.key}`]
          : true;
        if (item.otherKey && dependentInfo[`${item.otherKey}`]) {
          params[`${item.otherKey}`] = dependentInfo[`${item.otherKey}`]
            ? !dependentInfo[`${item.otherKey}`]
            : true;
        } else {
          params[`${item.otherKey}`] = true;
        }
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });

        if (item["message"]) {
          setShowMessage(true);
        }
      } else if (item.actionName === "REQUEST_API_CALL") {
        if (
          selectedRowInfo[`${item.mappingKey}`] &&
          selectedRowInfo[`${item.mappingKey}`].length > 0
        ) {
          await deleteData(item.apiUlr, {
            model_names: selectedRowInfo[`${item.mappingKey}`].map(
              (obj) => obj.name
            ),
          })
            .then((res) => {
              if (typeof res === "object") {
                setShowMessage(true);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else if (item.actionName === "ITERATE_ITEMS_ON_CLICK") {
        let params = {};
        let actionsList = Object.keys(dependentInfo).filter((keyName) =>
          keyName.includes(item.mappingValue)
        );
        if (actionsList.length > 0) {
          let nextKey = `${item.mappingValue} ${actionsList.length}`;
          params[`${nextKey}`] = dependentInfo[`${nextKey}`]
            ? !dependentInfo[`${nextKey}`]
            : true;
          if (item.otherKey && dependentInfo[`${item.otherKey}`]) {
            params[`${item.otherKey}`] = dependentInfo[`${item.otherKey}`]
              ? !dependentInfo[`${item.otherKey}`]
              : true;
          } else {
            params[`${item.otherKey}`] = true;
          }
        } else {
          params[`${item.key}`] = dependentInfo[`${item.key}`]
            ? !dependentInfo[`${item.key}`]
            : true;
          if (item.otherKey && dependentInfo[`${item.otherKey}`]) {
            params[`${item.otherKey}`] = dependentInfo[`${item.otherKey}`]
              ? !dependentInfo[`${item.otherKey}`]
              : true;
          } else {
            params[`${item.otherKey}`] = true;
          }
        }

        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });

        if (item["message"]) {
          setShowMessage(true);
        }
      } else if (item.actionName === "CALCULATE_ALLOCATED_QUANTITY") {
        let params = {};
        let updatedTableData = [];
        if (
          tableInfo[`${item.parent_table_key}`] &&
          selectedRowInfo[`${item.parent_table_key}`]
        ) {
          updatedTableData = tableInfo[`${item.parent_table_key}`].filter(
            (obj) => {
              if (
                selectedRowInfo[`${item.parent_table_key}`]
                  .map((rowObj) => rowObj.id)
                  .indexOf(obj.id) !== -1
              ) {
                let totalValue = 0;
                let increasedValue = 0;
                item.valuesUpdateOn.forEach((valueKey) => {
                  if (formEditedValues[`${valueKey}_allocated_qty`]) {
                    increasedValue =
                      increasedValue +
                      (parseFloat(
                        formEditedValues[`${valueKey}_allocated_qty`]
                      ) -
                        parseFloat(obj[`${valueKey}_allocated_qty`]));
                    obj[`${valueKey}_allocated_qty`] =
                      formEditedValues[`${valueKey}_allocated_qty`];
                  }
                  totalValue =
                    totalValue + parseFloat(obj[`${valueKey}_allocated_qty`]);
                  // if(formEditedValues[`Packs per store_${valueKey}`] && formEditedValues[`Eaches per store_${valueKey}`]){
                  // 	let packs_value = 12 * formEditedValues[`Packs per store_${valueKey}`] + 6 * formEditedValues[`Eaches per store_${valueKey}`]
                  // 	increasedValue = increasedValue + ( packs_value - obj[`${valueKey}_allocated_qty`] )
                  // 	obj[`${valueKey}_allocated_qty`] = packs_value
                  // 	totalValue = totalValue + parseFloat(obj[`${valueKey}_allocated_qty`])
                  // }else{
                  // 	totalValue = totalValue + parseFloat(obj[`${valueKey}_allocated_qty`])
                  // }
                });
                obj[`${item.totalKey}`] =
                  parseInt(obj[`${item.totalKey}`]) + increasedValue;
                obj[`${item.substractedKey}`] = totalValue;
                obj[`${item.assignedKey}`] = Math.round(
                  obj[`${item.totalKey}`] - totalValue
                );
              }
              return obj;
            }
          );
          params[`${item.parent_table_key}`] = [...updatedTableData];
          dispatch({
            type: "TABLE_DATA",
            payload: params,
          });
        }
        if (item["message"]) {
          setShowMessage(true);
        }
      }
      else if (item.actionName === "COPY_ROWS") {
        const rows = [...tableInfo[`${item.parent_table_key}`]];
        if (rows && rows.length > 0) {
          const rowsToAdd=item.updateData.map(obj => ({
            ...rows[obj.rowIndex],
            ...obj?.data
          }))
          dispatch({
            type: "TABLE_DATA",
            payload: { [item.parent_table_key]: [...rows,...rowsToAdd] }
          })
        }
      }else if (item.actionName === "SET_HIDE_ROWS") {
        const rows = [...tableInfo[`${item.parent_table_key}`]];
        if (rows && rows.length > 0) {
          item.data.forEach(obj => rows[obj.rowIndex]['#hidden'] = obj.visibility ? "N" : "Y")
          dispatch({
            type: "TABLE_DATA",
            payload: { [item.parent_table_key]: [...rows] }
          })
        }
      }else if (item.actionName === "UPDATE_PRIORITY_ORDER_TABLE") {
        const sourceTableData = [...tableInfo[`${item.source_table_key}`]];
        const parentTableData = [...tableInfo[`${item.parent_table_key}`]];
        
        if (sourceTableData && sourceTableData.length > 0) {
          let selectedRowIndex = formEditedValues["selectedRowIndex"]
          let product_supersession_sku_id = formEditedValues[item.columnKey]
          sourceTableData.filter((obj,index)=>{
            if(selectedRowIndex === index){
              obj[`${item.mappingKey}`] = obj[`${item.mappingKey}`] + `,${product_supersession_sku_id}`
            }
            return obj;
          })
          parentTableData.filter((obj,index)=>{
              obj[`${item.parentMappingKey}`] = obj.copy_sku_id
              return obj;
          })
          
          let payloadReq = {}
          payloadReq[`${item.source_table_key}`] = [...sourceTableData]
          payloadReq[`${item.parent_table_key}`] = [...parentTableData]
          dispatch({
            type: "TABLE_DATA",
            payload: payloadReq
          })
          dispatch({
            type: "FORM_EDITED_VALUES",
            payload: {
              isRefreshCells:true
            }
          })
        }
      }if (item.actionName === "ADD_ONE_TABLE_TO_ANOTHER") {
        const sourceData = tableInfo[`${item.source_table_key}`];
        let payload = {};
        payload[`${item.parent_table_key}`] = [...sourceData];
        dispatch({
          type: "TABLE_DATA",
          payload: payload,
        });
        if(item["dependentOn"]){
          let params = {}
          params[`${item.dependentOn.key}`] = true;
          if(item["dependentOn"]["level"]){
            params[`${item.dependentOn.key}`] = true;
            params[`mapped_store_${dependentInfo["active_store"]}`] = true;
          }
          dispatch({
            type: "DEPENDENT_COMPONENTS",
            payload: params,
          });
        }
      } else {
        invokeAction(item)
      }
        if (item?.closeParentModalOnAction) {
          modalRefs[item?.parent_modal_key].current.close()
        }
    }
  };

  const handleClose = async (item, index) => {
    if (item ) {
      if(Array.isArray(item)){
        item.forEach((obj,i)=>{
           onAction(obj,i)
        })
      }
      else{
        onAction(item, index);
      }
    }
    if (item && item["resetDefaultDependentOn"]) {
      let storeParams = { ...dependentInfo };
      item.resetDefaultDependentOn.forEach((keyName) => {
        delete storeParams[`${keyName}`];
      });
      dispatch({
        type: "RESET_DATA",
        payload: { dependentInfo: storeParams },
      });
    }
    setOpen(false);
    if(props.setOpenAssort){
      setOpenAssort(false)
    }
    if (props.handleClose) {
      props.handleClose();
    }
  };
  const handleMessageClose = () => {
    setShowMessage(false);
    const details =props.footer_actions[0]?.info?.details[1]
    if (details?.action?.navigationPath) {
      navigate(
        "/" +
          window?.location?.pathname?.split("/")[1] +
          "/" +
          window?.location?.pathname?.split("/")[2] +
          details?.action?.navigationPath
      );
    }
  };
  useEffect(() => {
    if (props?.modal_key) {
      modalRef.current = { close: handleClose };
      dispatch({
        type: "MODAL_REFS",
        payload: { [props.modal_key]: modalRef }
      })
    }
  }, [])

  // function if we have to navigate
  function handleClick(item){
      if (item?.action?.actionName === "showActiveTab") {
        if (showActiveTab !== true) {
          dispatch({
            type: "SHOW_ACTIVE_TAB",
            payload: item.action,
          });
        } else {
          dispatch({
            type: "NEWLY_ADDED_TAB_INDEX",
            payload: newDynamicTabIndexValue + 1,
          });
          dispatch({
            type: "ADD_DYNAMIC_SCENARIO",
            payload: [
              item?.action?.dynamicTabComponent,
              newDynamicTabIndexValue,
            ],
          });
        }
      } else if (item?.action?.actionName === "ENABLE_DISABLE_BUTTON_KEYS") {
        if (item?.action?.path) {
          setTimeout(() => {
            navigate(
              "/" +
                window?.location?.pathname?.split("/")[1] +
                "/" +
                window?.location?.pathname?.split("/")[2] +
                item?.action?.path
            );
          }, 0);
        }
        let params = {};
        params[item?.action?.key] = true;
        params[item?.action?.key1] = item?.action?.key1 ? true : null;
        params[item?.action?.key2] = item?.action?.key2 ? true : null;
        params[item?.action?.key3] = item?.action?.key3 ? true : null;
        params[item?.action?.key4] = item?.action?.key4 ? true : null;
        params[item?.action?.key5] = item?.action?.key5 ? true : null;
        params[item?.action?.key6] = item?.action?.key6 ? true : null;
        params[item?.action?.otherKey1] = item?.action?.otherKey1 ? false : null;
        params[item?.action?.otherKey2] = item?.action?.otherKey2 ? false : null;
        params[item?.action?.otherKey3] = item?.action?.otherKey3 ? false : null;
        params[item?.action?.otherKey4] = item?.action?.otherKey4 ? false : null;
        params[item?.action?.otherKey5] = item?.action?.otherKey5 ? false : null;
        params[item?.action?.otherKey6] = item?.action?.otherKey6 ? false : null;
        dispatch({
          type: "DEPENDENT_COMPONENTS",
          payload: params,
        });
      }
      handleClose(item.action, 0)
  }

  if(components && footer_actions && footer_actions.length > 0){
    return (
      <>
        {showMessage && (
          <>
            <SnackBar
              message={props.footer_actions[0].info.details[0].action.message}
              variant="success"
              handleClose={handleMessageClose}
            />
          </>
        )}
        <Modal
          isPortal
          container={document.body}
          size={width === 'lg'? 'large': width === 'medium'? 'medium': 'small'}
          heading={title}
          isOpen={open}
          onClose={handleClose}
          primaryButtonProps={{ children: footer_actions[0]?.info?.details[0]?.name, onClick: footer_actions[0]?.info?.details[0]?.dependentOn ? onAction(footer_actions[0]?.info?.details[0], 0):  () => handleClick(footer_actions[0]?.info?.details[0])}}
          tertiaryButtonProps={{ children: footer_actions[0]?.info?.cancelLabel||'Cancel', onClick: handleClose}}
          >
          {props.statement ? (
              <p>{props.statement}</p>
            ) : components? (
              <ComponentResolver pageObject={{ components: components }} />
            ): <LoadingOverlay/>}
        </Modal>
      </>
    );
  }else{
    return (
      <>
        <Modal
          isPortal
          container={document.body}
          size={width === 'lg'? 'large': width === 'medium'? 'medium': 'small'}
          heading={title}
          isOpen={open}
          onClose={handleClose}
          primaryButtonProps={{ children:'Close', onClick: handleClose}}
          >
          {props.statement ? (
              <p>{props.statement}</p>
            ) : props.components? (
              <ComponentResolver pageObject={{ components: props.components }} />
            ): <LoadingOverlay/>}
        </Modal>
      </>
    );
  }
  
};



//footer_actions[0]?.info?.details[0]?.name !== 'Cancel'? footer_actions[0]?.info?.details[0]?.name: null,
//onClick: footer_actions[0]?.info?.details[0]?.dependentOn ? onAction(footer_actions[0]?.info?.details[0], 0):  () => handleClick(footer_actions[0]?.info?.details[0]) }}