import React from 'react';
// Impact UI
import { Button } from "impact-ui";

export default function ButtonWithoutIcon({item, onAction, index, dependentInfo}) {
  return (
    <Button
        disabled={
            item?.disabledBy
                ? !dependentInfo[`${item["disabledBy"]}`]
                : false
        }
        variant={item.varient === "contained"? "primary": item.varient === 'text'? "url": "secondary"}
        size={!item.size || item.size == "medium" ? "normal" : "small"}
        title={item.name}
        className={item?.className}
        onClick={() => {
            if (Array.isArray(item.action)) {
                item.action.forEach((action) =>
                    onAction(action, index)
                );
            } else onAction(item.action, index);
        }}>
        {item.name}
    </Button>
  )
}
