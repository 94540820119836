import { Drawer } from "@material-ui/core";
import { useState } from "react";
import ComponentResolver from "../Resolver/ComponentResolver";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { Panel } from "impact-ui";

let drawerHeaderContainer = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid #D4D4D4",
  padding: "20px",
};

let drawerHeaderText = {
  fontSize: "14px",
  fontWeight: "600",
  color: "#1D1D1D",
};

let drawerComponentContainer = {
  height: "100%",
  position: "relative",
  padding: "20px",
};

const CustomDrawer = (props) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    props.setOpenDrawer && props.setOpenDrawer(false);
  };


  return (
    // <Drawer anchor={"right"}  open={props.openDrawer || open} onKeyDown={() => setOpen(false)}>
    //   <Box style={drawerHeaderContainer} >
    //     <div className="drawer-header" style={drawerHeaderText}>
    //       {props.info.header}
    //     </div>
    //     <CloseIcon onClick={handleClose} />
    //   </Box>
    //   <Box style={{width: props.info?.width || "max-content", ...drawerComponentContainer}}>
    //     <ComponentResolver
    //       pageObject={{ components: props?.info?.components }}
    //     />
    //   </Box>
    // </Drawer>
    <Panel
      size={props?.info?.size || "medium"}
      isOpen={props.openDrawer || open}
      onClose={handleClose}
      title={props?.info?.header}
      primaryButtonProps={{ children: "Ok" }}
      tertiaryButtonProps={{ children: "Cancel", onClick: () => handleClose() }}
    >
      <ComponentResolver
          pageObject={{ components: props?.info?.components }}
        />
    </Panel>
  );
};

export default CustomDrawer;

{
  /* <>
      <Drawer anchor={"right"} open={props.openDrawer || open} onKeyDown={() => setOpen(false)}>
        <Box
          style={{ minWidth: props.info?.width || "max-content", padding: "20px" }}
        >
        <Box textAlign={"right"}>
          <CloseIcon onClick={handleClose}/>
        </Box>
          <ComponentResolver pageObject={{ components: props?.info?.components }} />
        </Box>
      </Drawer>
    </> */
}
