import React from "react";
import ButtonDropdown from "../ButtonDropdown";
// Impact UI
import { Button } from "impact-ui";

export default function ButtonDependent({
  item,
  onAction,
  index,
  dependentInfo,
  getIcon,
}) {
  if (item[`dependentOn`]) {
    return (
      <>
        {dependentInfo[`${item[`dependentOn`]}`] ? (
          <>
            {item["type"] === "buttonDropdown" ? (
              <ButtonDropdown data={item} isDivNotRequired={true} />
            ) : (
              <Button
                //   style={{
                //     textTransform: "none",
                //     margin: 5,
                //     width: item.width,
                //     backgroundColor:
                //       item.varient === "contained"
                //         ? "#0055AF"
                //         : null,
                //     boxShadow: "none",
                //   }}
                disabled={
                  item?.disabledBy
                    ? !dependentInfo[`${item["disabledBy"]}`]
                    : false
                }
                variant={item.varient === "contained"? "primary": item.varient === 'text'? "url": "secondary"}
                size={!item.size || item.size == "medium" ? "normal" : "small"}
                title={item.name}
                // disabled={obj.disableFeat && diable}
                onClick={() => {
                  if (Array.isArray(item.action)) {
                    item.action.forEach((action) => onAction(action, index));
                  } else onAction(item.action, index);
                }}
                icon={() => getIcon(item, index)}
              >
                {item.name}
              </Button>
            )}
          </>
        ) : null}
      </>
    );
  }
}
