import { useSelector } from "react-redux";
export default function DynamicDataLabel({ data }) {
  const {
    sourceId,
    label,
    labelStyle = {},
    valueStyle = {},
    defaultValue,
    containerStyle,
  } = data;
  const { buffer } = useSelector((state) => state.home);
  const value = sourceId ? buffer[sourceId] || "" : defaultValue;
  return (
    <div
      style={{
        display: "flex",
        gap: "8px",
        alignItems: "center",
        ...containerStyle,
      }}
    >
      <p style={{ fontSize: "12px", color: "#697681", ...labelStyle }}>
        {label}
      </p>
      <p
        style={{
          fontSize: "14px",
          textOverflow: "ellipsis",
          maxWidth: "200px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          ...valueStyle,
        }}
      >
        {value}
      </p>
    </div>
  );
}
