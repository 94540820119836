import React from "react";
// Impact UIgetIAIcon
import { Button, Tooltip } from "impact-ui";

// Material UI Icons
import AddIcon from "@mui/icons-material/Add";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import EditIcon from "@mui/icons-material/Edit";
import ListIcon from "@mui/icons-material/List";
import UpdateIcon from "@mui/icons-material/Update";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import SwapVertOutlinedIcon from "@mui/icons-material/SwapVertOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DescriptionIcon from "@mui/icons-material/Description";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import SaveIcon from "@mui/icons-material/Save";
import CachedIcon from "@mui/icons-material/Cached";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SettingsIcon from "@mui/icons-material/Settings";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import UploadIcon from "@mui/icons-material/Upload";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import BarChartIcon from "@mui/icons-material/BarChart";
import CloseFullscreenOutlinedIcon from "@mui/icons-material/CloseFullscreenOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ImportExportOutlinedIcon from "@mui/icons-material/ImportExportOutlined";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import PostAddIcon from "@mui/icons-material/PostAdd";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import CalculateIcon from "@mui/icons-material/Calculate";
import CompareIcon from "@mui/icons-material/Compare";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import UndoIcon from "@mui/icons-material/Undo";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import SyncIcon from "@mui/icons-material/Sync";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import TableChartIcon from "@mui/icons-material/TableChart";
import TuneIcon from '@mui/icons-material/Tune';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import NoteAddIcon from "@mui/icons-material/NoteAdd";

import {
  DownloadOutlined,
  InfoOutlined,
  OpenInFull,
  TableChartOutlined,
} from "@mui/icons-material";
export const getButtonIcon = (
  item,
  index,
  selectedRowInfo,
  editActionInfo,
  onAction,
  onIconClickAction
) => {
  const { parent_table_key, iconType } = item;
  switch (iconType) {
    case "Arrow":
      return <KeyboardReturnIcon />;
    case "Open":
      return <FileOpenIcon />;
    case "Add":
      return (
        <>
          {parent_table_key ? (
            <>
              {selectedRowInfo[`${parent_table_key}`] &&
              editActionInfo[`${parent_table_key}`] ? (
                <Button
                  variant={
                    item.varient === "contained"
                      ? "primary"
                      : item.varient === "text"
                      ? "url"
                      : "secondary"
                  }
                  size={
                    !item.size || item.size == "medium" ? "normal" : "small"
                  }
                  title={item.name}
                  onClick={() => onIconClickAction(item, index)}
                >
                  <AddIcon />
                </Button>
              ) : (
                <></>
              )}
            </>
          ) : (
            <AddIcon onClick={() => onAction(item?.action)} />
          )}
        </>
      );
      break;
    case "Edit":
      return (
        <>
          {parent_table_key ? (
            <>
              {selectedRowInfo[`${parent_table_key}`] &&
              editActionInfo[`${parent_table_key}`] ? (
                <Button
                  variant={
                    item.varient === "contained"
                      ? "primary"
                      : item.varient === "text"
                      ? "url"
                      : "secondary"
                  }
                  size={
                    !item.size || item.size == "medium" ? "normal" : "small"
                  }
                  title={item.name}
                  onClick={() => {
                    if (item?.action && item?.callAction) {
                      if (Array.isArray(item.action)) {
                        item.action.forEach((action, index) =>
                          onAction(action, index)
                        );
                      } else {
                        onAction(item.action, index);
                      }
                    } else onIconClickAction(item, index);
                  }}
                >
                  <UpdateIcon />
                </Button>
              ) : (
                <>
                  <Button
                    style={{ textTransform: "none" }}
                    variant={
                      item.varient === "contained"
                        ? "primary"
                        : item.varient === "text"
                        ? "url"
                        : "secondary"
                    }
                    size={
                      !item.size || item.size == "medium" ? "normal" : "small"
                    }
                    title={item.name}
                    onClick={() => {
                      if (item?.action && item?.callAction) {
                        if (Array.isArray(item.action)) {
                          item.action.forEach((action, index) =>
                            onAction(action, index)
                          );
                        } else {
                          onAction(item.action, index);
                        }
                      } else onIconClickAction(item, index);
                    }}
                  >
                    <EditIcon />
                  </Button>
                </>
              )}
            </>
          ) : (
            <EditIcon />
          )}
        </>
      );
      break;
    case "Delete":
      return (
        <>
          {parent_table_key ? (
            <>
              {selectedRowInfo[`${parent_table_key}`] &&
              editActionInfo[`${parent_table_key}`] ? null : (
                <>
                  <Button
                    style={{ textTransform: "none", margin: 5 }}
                    variant={
                      item.varient === "contained"
                        ? "primary"
                        : item.varient === "text"
                        ? "url"
                        : "secondary"
                    }
                    size={item.size}
                    title={item.name}
                    onClick={() => onIconClickAction(item, index)}
                  >
                    <DeleteIcon />
                  </Button>
                </>
              )}
            </>
          ) : (
            <DeleteIcon />
          )}
        </>
      );
      break;
    case "List":
      return <ListIcon />;
      break;
    case "Copy":
      return <ContentCopyIcon />;
    case "DeleteOutlined":
      return <DeleteOutlineIcon />;
    case "EditIcon":
      return (
        <EditIcon
          onClick={() => {
            if (Array.isArray(item.action)) {
              item.action.forEach((action) => onAction(action, index));
            } else {
              onAction(item.action, index);
            }
          }}
        />
      );
    case "UpdateIcon":
      return <UpdateIcon />;
    case "Visible":
      return <VisibilityIcon />;
    case "Calendar":
      return <CalendarViewMonthIcon />;
    case "Analytics":
      return <AssessmentOutlinedIcon />;
    case "AddNewRowBasedOnEdit":
      return (
        <>
          {editActionInfo[`${parent_table_key}`] || item.default_open ? (
            <>
              <Button
                style={{ textTransform: "none" }}
                variant={
                  item.varient === "contained"
                    ? "primary"
                    : item.varient === "text"
                    ? "url"
                    : "secondary"
                }
                size={!item.size || item.size == "medium" ? "normal" : "small"}
                title={item.name}
                onClick={() => onIconClickAction(item, index)}
              >
                <AddIcon />
              </Button>
            </>
          ) : null}
        </>
      );

      break;

    case "Update":
      return editActionInfo[`${parent_table_key}`] ? (
        <>
          <Button
            style={{ textTransform: "none", margin: 5 }}
            variant={
              item.varient === "contained"
                ? "primary"
                : item.varient === "text"
                ? "url"
                : "secondary"
            }
            size={!item.size || item.size == "medium" ? "normal" : "small"}
            title={item.name}
            onClick={() => onIconClickAction(item, index)}
          >
            <UpdateIcon />
          </Button>
        </>
      ) : (
        <UpdateIcon />
      );
      break;
    case "Download":
      return <DownloadIcon />;
    case "Upload":
      return <UploadIcon />;
    case "SettingIcon":
      return <SettingsIcon />;
    case "dot":
      return <FiberManualRecordIcon style={item?.iconStyle} />;
    case "filter":
      return <FilterAltOutlinedIcon />;
    case "ExpandCircleDownIcon":
      return <ExpandCircleDownIcon />;
    case "chart":
      return <ShowChartIcon />;
    case "save":
      return <SaveIcon />;
    case "reload":
      return <CachedIcon />;
    case "returnIcon":
      return;
    case "remove":
      return <CloseIcon />;
    case "Reset":
      return <RestartAltIcon />;
    case "Next":
      return <NavigateNextIcon />;
    case "Previous":
      return <ArrowBackIosIcon />;
    case "bulletList":
      return <FormatListBulletedIcon />;
    case "barChart":
      return <BarChartIcon />;
    case "fullScreen":
      return <CloseFullscreenOutlinedIcon />;
    case "backArrow":
      return <ArrowBackOutlinedIcon />;
    case "help":
      return <HelpOutlineOutlinedIcon />;
    case "search":
      return <SearchOutlinedIcon />;
    case "sort":
      return <ImportExportOutlinedIcon />;
    case "trending":
      return <TrendingUpIcon />;
    case "whatshot":
      return <WhatshotIcon />;
    case "AccountTree":
      return <AccountTreeIcon />;
    case "PostAdd":
      return <PostAddIcon />;
    case "Swap":
      return <SwapVertIcon />;
    case "Up":
      return <NorthIcon />;
    case "Down":
      return <SouthIcon />;
    case "Calculate":
      return <CalculateIcon />;
    case "Compare":
      return <CompareIcon />;
    case "queryStats":
      return <QueryStatsIcon />;
    case "NoteAdd":
      return <NoteAddOutlinedIcon />;
    case "Lock":
      return <LockOutlinedIcon />;
    case "Unlock":
      return <LockOpenOutlinedIcon />;
    case "History":
      return <HistoryOutlinedIcon />;
    case "tableChart":
      return <TableChartIcon />;
    case "expandDown":
      return (
        <svg viewBox="0 0 12 6">
          <path d="M5.23662 5.69704L0.223063 1.1221C0.153906 1.059 0.0994398 0.98853 0.0596639 0.910711C0.019888 0.832868 0 0.749471 0 0.66052C0 0.48262 0.0659226 0.32806 0.197767 0.196836C0.329584 0.0656129 0.503338 0 0.719029 0L11.281 0C11.4967 0 11.6704 0.0662421 11.8022 0.198724C11.9341 0.331229 12 0.485803 12 0.662446C12 0.70663 11.9255 0.859946 11.7766 1.12239L6.76338 5.69707C6.64814 5.80223 6.52901 5.87901 6.40599 5.92741C6.28295 5.9758 6.14762 6 6 6C5.85238 6 5.71705 5.9758 5.59401 5.92741C5.47099 5.87901 5.35187 5.80222 5.23662 5.69704Z" />
        </svg>
      );
    case "expandLeft":
      return (
        <svg viewBox="0 0 6 12">
          <path d="M5.69704 6.76338L1.1221 11.7769C1.059 11.8461 0.98853 11.9006 0.910711 11.9403C0.832868 11.9801 0.749471 12 0.66052 12C0.48262 12 0.32806 11.9341 0.196836 11.8022C0.0656129 11.6704 -2.20016e-08 11.4967 -3.14298e-08 11.281L-4.93107e-07 0.719028C-5.02535e-07 0.503337 0.0662416 0.329583 0.198723 0.197766C0.331229 0.0659218 0.485803 9.32439e-07 0.662445 9.24718e-07C0.70663 9.22787e-07 0.859945 0.07446 1.12239 0.223381L5.69707 5.23662C5.80223 5.35186 5.87901 5.47099 5.92741 5.59401C5.9758 5.71705 6 5.85238 6 6C6 6.14762 5.9758 6.28295 5.92741 6.40599C5.87901 6.52901 5.80222 6.64813 5.69704 6.76338Z" />
        </svg>
      );
    case "editSquare":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 -960 960 960"
          width="20px"
          fill="#0055af"
        >
          <path d="M216-144q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-30.11 21-51.56Q186-817 216-816h346l-72 72H216v528h528v-274l72-72v346q0 29.7-21.15 50.85Q773.7-144 744-144H216Zm264-336Zm-96 96v-153l354-354q11-11 24-16t26.5-5q14.4 0 27.45 5 13.05 5 23.99 15.78L891-840q11 11 16 24.18t5 26.82q0 13.66-5.02 26.87-5.02 13.2-15.98 24.13L537-384H384Zm456-405-51-51 51 51ZM456-456h51l231-231-25-26-26-25-231 231v51Zm257-257-26-25 26 25 25 26-25-26Z" />
        </svg>
      );
    case "nextArrow":
      return <span class="material-symbols-outlined">arrow_forward_ios</span>;
    case "ExpandMore":
      return <ExpandMoreIcon />;
    case "ExpandLess":
      return <ExpandLessIcon />;
    case "menuBook":
      return <MenuBookIcon />;
    case "undo":
      return <UndoIcon />;
    case "swap":
      return <SwapHorizIcon />;
    case "constraints":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#0055AF"
        >
          <path d="m234-480-12-60q-12-5-22.5-10.5T178-564l-58 18-40-68 46-40q-2-13-2-26t2-26l-46-40 40-68 58 18q11-8 21.5-13.5T222-820l12-60h80l12 60q12 5 22.5 10.5T370-796l58-18 40 68-46 40q2 13 2 26t-2 26l46 40-40 68-58-18q-11 8-21.5 13.5T326-540l-12 60h-80Zm40-120q33 0 56.5-23.5T354-680q0-33-23.5-56.5T274-760q-33 0-56.5 23.5T194-680q0 33 23.5 56.5T274-600ZM592-40l-18-84q-17-6-31.5-14.5T514-158l-80 26-56-96 64-56q-2-18-2-36t2-36l-64-56 56-96 80 26q14-11 28.5-19.5T574-516l18-84h112l18 84q17 6 31.5 14.5T782-482l80-26 56 96-64 56q2 18 2 36t-2 36l64 56-56 96-80-26q-14 11-28.5 19.5T722-124l-18 84H592Zm56-160q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Z" />
        </svg>
      );
    case "sync":
      return <SyncIcon />;
    case "Excel":
      return <FontAwesomeIcon icon={faFileExcel} size="2x" />;
    case "downloadOutlined":
      return <DownloadOutlined />;
    case "table":
      return <TableChartOutlined />;
    case "scale":
      return <OpenInFull />;
    case "match":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#0055AF"
        >
          <path d="m430-30-56-57 73-73H313q-13 35-43.5 57.5T200-80q-50 0-85-35t-35-85q0-39 22.5-69.5T160-313v-334q-35-13-57.5-43.5T80-760q0-50 35-85t85-35q39 0 69.5 22.5T313-800h134l-73-73 56-57 170 170-170 170-56-57 73-73H313q-9 26-28 45t-45 28v334q26 9 45 28t28 45h134l-73-73 56-57 170 170L430-30Zm330-50q-50 0-85-35t-35-85q0-40 22.5-70.5T720-313v-334q-35-12-57.5-42.5T640-760q0-50 35-85t85-35q50 0 85 35t35 85q0 40-22.5 70.5T800-647v334q35 13 57.5 43.5T880-200q0 50-35 85t-85 35Zm-560-80q17 0 28.5-11.5T240-200q0-17-11.5-28.5T200-240q-17 0-28.5 11.5T160-200q0 17 11.5 28.5T200-160Zm560 0q17 0 28.5-11.5T800-200q0-17-11.5-28.5T760-240q-17 0-28.5 11.5T720-200q0 17 11.5 28.5T760-160ZM200-720q17 0 28.5-11.5T240-760q0-17-11.5-28.5T200-800q-17 0-28.5 11.5T160-760q0 17 11.5 28.5T200-720Zm560 0q17 0 28.5-11.5T800-760q0-17-11.5-28.5T760-800q-17 0-28.5 11.5T720-760q0 17 11.5 28.5T760-720ZM200-200Zm560 0ZM200-760Zm560 0Z" />
        </svg>
      );
    case "addNotes":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#0055AF"
        >
          <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v268q-19-9-39-15.5t-41-9.5v-243H200v560h242q3 22 9.5 42t15.5 38H200Zm0-120v40-560 243-3 280Zm80-40h163q3-21 9.5-41t14.5-39H280v80Zm0-160h244q32-30 71.5-50t84.5-27v-3H280v80Zm0-160h400v-80H280v80ZM720-40q-83 0-141.5-58.5T520-240q0-83 58.5-141.5T720-440q83 0 141.5 58.5T920-240q0 83-58.5 141.5T720-40Zm-20-80h40v-100h100v-40H740v-100h-40v100H600v40h100v100Z" />
        </svg>
      );
    case "stylusNotes":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#0055AF"
        >
          <path d="m499-287 335-335-52-52-335 335 52 52Zm-261 87q-100-5-149-42T40-349q0-65 53.5-105.5T242-503q39-3 58.5-12.5T320-542q0-26-29.5-39T193-600l7-80q103 8 151.5 41.5T400-542q0 53-38.5 83T248-423q-64 5-96 23.5T120-349q0 35 28 50.5t94 18.5l-4 80Zm280 7L353-358l382-382q20-20 47.5-20t47.5 20l70 70q20 20 20 47.5T900-575L518-193Zm-159 33q-17 4-30-9t-9-30l33-159 165 165-159 33Z" />
        </svg>
      );
    case "outwardArrow":
      return <ArrowOutwardIcon />;
    case "like":
      return <ThumbUpOutlinedIcon />;
    case "blocks":
      return <DashboardCustomizeOutlinedIcon />;
    case "imageEdit":
      return <NoteAltOutlinedIcon />;
    case "newFilters":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.1668 8.00009H5.93016M3.02283 8.00009H1.8335M3.02283 8.00009C3.02283 7.61464 3.17595 7.24498 3.4485 6.97243C3.72105 6.69988 4.09071 6.54676 4.47616 6.54676C4.86161 6.54676 5.23127 6.69988 5.50382 6.97243C5.77638 7.24498 5.9295 7.61464 5.9295 8.00009C5.9295 8.38554 5.77638 8.7552 5.50382 9.02775C5.23127 9.3003 4.86161 9.45342 4.47616 9.45342C4.09071 9.45342 3.72105 9.3003 3.4485 9.02775C3.17595 8.7552 3.02283 8.38554 3.02283 8.00009ZM14.1668 12.4048H10.3348M10.3348 12.4048C10.3348 12.7903 10.1813 13.1604 9.90873 13.433C9.63611 13.7056 9.26637 13.8588 8.88083 13.8588C8.49538 13.8588 8.12572 13.705 7.85317 13.4324C7.58061 13.1599 7.4275 12.7902 7.4275 12.4048M10.3348 12.4048C10.3348 12.0192 10.1813 11.6498 9.90873 11.3772C9.63611 11.1046 9.26637 10.9514 8.88083 10.9514C8.49538 10.9514 8.12572 11.1045 7.85317 11.3771C7.58061 11.6496 7.4275 12.0193 7.4275 12.4048M7.4275 12.4048H1.8335M14.1668 3.59542H12.0968M9.1895 3.59542H1.8335M9.1895 3.59542C9.1895 3.20998 9.34261 2.84031 9.61517 2.56776C9.88772 2.29521 10.2574 2.14209 10.6428 2.14209C10.8337 2.14209 11.0227 2.17968 11.199 2.25272C11.3753 2.32576 11.5355 2.43281 11.6705 2.56776C11.8054 2.70272 11.9125 2.86293 11.9855 3.03926C12.0586 3.21558 12.0962 3.40457 12.0962 3.59542C12.0962 3.78628 12.0586 3.97526 11.9855 4.15159C11.9125 4.32792 11.8054 4.48813 11.6705 4.62308C11.5355 4.75804 11.3753 4.86509 11.199 4.93813C11.0227 5.01117 10.8337 5.04876 10.6428 5.04876C10.2574 5.04876 9.88772 4.89564 9.61517 4.62308C9.34261 4.35053 9.1895 3.98087 9.1895 3.59542Z"
            stroke="#0055AF"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
        </svg>
      );
    case "info": {
      return (
        <Tooltip
          text={item.tooltipText}
          disabled={!Boolean(item.tooltipText) || item.disabledTooltip}
          placement={item.tooltipPlacement || "bottom"}
          isPortal={item.isPortal || false}
          zIndex={item.zIndex || 5}
        >
          <InfoOutlined sx={{ ...item?.infoStyle }} />
        </Tooltip>
      );
    }
    case "slideShow":
      return <SlideshowIcon />;
    case "database":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 17V7C20 4.832 16.337 3 12 3C7.663 3 4 4.832 4 7V17C4 19.168 7.663 21 12 21C16.337 21 20 19.168 20 17ZM12 5C15.691 5 17.931 6.507 18 6.994C17.931 7.493 15.691 9 12 9C8.309 9 6.069 7.493 6 7.006C6.069 6.507 8.309 5 12 5ZM6 9.607C7.479 10.454 9.637 11 12 11C14.363 11 16.521 10.454 18 9.607V11.994C17.931 12.493 15.691 14 12 14C8.309 14 6.069 12.493 6 12V9.607ZM6 17V14.607C7.479 15.454 9.637 16 12 16C14.363 16 16.521 15.454 18 14.607V16.994C17.931 17.493 15.691 19 12 19C8.309 19 6.069 17.493 6 17Z"
            fill="#0055AF"
          />
        </svg>
      );
    case "forwardArrow":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#e8eaed"
        >
          <path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z" />
        </svg>
      );
    case "Tune":
      return <TuneIcon />;
    case "Menu":
      return <MoreHorizIcon />;
    case "Sales":
      return <AttachMoneyIcon />;
    case "Quantity":
      return <ProductionQuantityLimitsIcon />;
    case "Quantity":
      return <OpenInFull />;
    case "noteAdd":
      return <NoteAddIcon />;
    default:
      return <AddIcon />;
  }
};
