import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//import Button from "@mui/material/Button";
//import { CustomModal } from "./customModal";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { addNotifications } from "../data";
import EditIcon from "@mui/icons-material/Edit";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

import SnackBar from "../../../componentsV2/SnackBar";
import { CustomModal } from "./CustomModal";
import { Button } from "impact-ui";
import { IconMenuItem } from "mui-nested-menu";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import PostAddIcon from "@mui/icons-material/PostAdd";
import UndoIcon from "@mui/icons-material/Undo";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { RiFileExcel2Fill } from "react-icons/ri";
import { GrDocumentCsv } from "react-icons/gr";
import useAction from "../../../utils/useAction";
export default function MenuPopupState(props) {
  const { data } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [itemIndex, setItemIndex] = useState(null);
  const [label, setLabel] = useState(data?.label);
  const { apiData, dependentInfo, tableInfo, selectedRowInfo, gridRefs } =
    useSelector((state) => state.home);
  const invokeAction = useAction();
  const onAction = (action, index, popupState) => {
    if (action.actionName === "navigate") {
      if (action.otherActionName === "notifications") {
        addNotifications(action);
      }
      navigate(
        "/" +
          window?.location?.pathname?.split("/")[1] +
          "/" +
          window?.location?.pathname?.split("/")[2] +
          action.path
      );
    } else if (action.actionName === "modal") {
      setOpen(!open);
      setItemIndex(index);
    } else if (action.actionName === "click") {
      let params = {};
      params[`${action.key}`] = dependentInfo[`${action.key}`]
        ? !dependentInfo[`${action.key}`]
        : true;
      if (action.otherKey && dependentInfo[`${action.otherKey}`]) {
        params[`${action.otherKey}`] = dependentInfo[`${action.otherKey}`]
          ? !dependentInfo[`${action.otherKey}`]
          : true;
      } else {
        params[`${action.otherKey}`] = true;
      }
      if (action["defaultToggle"]) {
        params[`${action.key}`] = action.defaultToggle;
      }
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "DROPDOWN_DINNERWARE_ACTION") {
      let params = {};
      params[`plate-store-cluster`] = false;
      params[`dinnerware-store-cluster`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "ENABLE_DISABLE_KEYS") {
      let params = {};
      params[action.key] = true;
      params[action.otherKey] = false;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "DROPDOWN_PLATE_ACTION") {
      let params = {};
      params[`dinnerware-store-cluster`] = false;
      params[`plate-store-cluster`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "ENABLE_DISABLE_KEYS") {
      let params = {};
      params[action.key] = true;
      params[action.otherKey] = false;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (action.actionName === "displayMessage") {
      setShowMessage(true);
      setMessage(action.message);
    } else if (action.actionName === "COPY_COLUMN_DATA") {
      const selectedRows = gridRefs[action.parent_table_key]?.current.api
        .getSelectedNodes()
        .map((node) => node.rowIndex);
      if (selectedRows && selectedRows.length > 0) {
        const rows = [...tableInfo[action.parent_table_key]];
        selectedRows.forEach(
          (rowIndex) =>
            (rows[rowIndex][action.dest_field] =
              rows[rowIndex][action.source_field])
        );
        dispatch({
          type: "TABLE_DATA",
          payload: { [action.parent_table_key]: [...rows] },
        });
      }
    } else {
      invokeAction(action);
    }
    popupState.close();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMessageClose = () => {
    setShowMessage(false);
    setMessage(null);
  };

  const renderButtonWithIcon = (popupState, icon) => {
    return (
      <Button
        // style={{
        // 	textTransform: "none",
        // }}
        variant={data.varient === "contained" ? "primary" : "secondary"}
        size="normal"
        className={data.isIAIconButton && "ia-icon-button"}
        {...bindTrigger(popupState)}
        icon={() => icon}
        //startIcon={icon}
        // sx={{
        // 	"& .MuiButton-startIcon": { margin: "0px" },
        // 	...data?.style,
        // }}
      >
        {data?.label}
      </Button>
    );
  };

  const IconSelector = (props, popupState) => {
    let icon;

    switch (props.iconType) {
      case "edit":
        icon = <EditIcon />;
        return renderButtonWithIcon(popupState, icon);
      case "download":
        icon = <DownloadIcon />;
        return renderButtonWithIcon(popupState, icon);

      case "time":
        icon = <AccessTimeIcon />;
        return renderButtonWithIcon(popupState, icon);

      case "copy":
        icon = <ContentCopyOutlinedIcon />;
        return renderButtonWithIcon(popupState, icon);
      case "menu":
        return (
          <Button
            variant={data.varient === "contained" ? "primary" : "secondary"}
            size="normal"
            className={
              data.isIAIconButton && (data?.className || "ia-icon-button")
            }
            {...bindTrigger(popupState)}
            icon={() =>
              !data?.isIAIconButton ? (
                <MoreVertIcon />
              ) : data.horizontal_icon ? (
                <MoreHorizIcon />
              ) : (
                <span className="material-symbols-outlined">more_vert</span>
              )
            }
          >
            {data?.label}
          </Button>
        );
      default:
        return (
          <Button
            //style={{ textTransform: "none", margin: 5, width: data.width }}
            variant={data.variant === "contained" ? "primary" : "secondary"}
            size="medium"
            {...bindTrigger(popupState)}
          >
            {label}
          </Button>
        );
    }
  };

  const IconRenderer = (type) => {
    switch (type) {
      case "undo":
        return <span class="material-symbols-outlined">undo</span>;
      case "refresh":
        return <span class="material-symbols-outlined">refresh</span>;
      case "setting":
        return <span class="material-symbols-outlined">settings</span>;
      case "match":
        return <span class="material-symbols-outlined">rebase</span>;
      case "calculate":
        return <span class="material-symbols-outlined">calculate</span>;
      case "download":
        return <span class="material-symbols-outlined">download_2</span>;
      case "edit":
        return <span class="material-symbols-outlined">edit</span>;
      case "search":
        return <span class="material-symbols-outlined">search</span>;
      case "delete":
        return <span class="material-symbols-outlined">delete</span>;
      case "copy":
        return <span class="material-symbols-outlined">content_copy</span>;
      case "graph":
        return (
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.698 12.5359H2.988C2.808 12.5359 2.664 12.673 2.664 12.8445V16.0857C2.664 16.2572 2.808 16.3944 2.988 16.3944H4.698C4.878 16.3944 5.022 16.2572 5.022 16.0857V12.8445C5.022 12.673 4.878 12.5359 4.698 12.5359ZM7.974 10.5809H6.264C6.084 10.5809 5.94 10.7181 5.94 10.8896V16.0857C5.94 16.2572 6.084 16.3944 6.264 16.3944H7.974C8.154 16.3944 8.298 16.2572 8.298 16.0857V10.8896C8.298 10.7181 8.154 10.5809 7.974 10.5809ZM11.304 8.40297H9.594C9.414 8.40297 9.27 8.54016 9.27 8.71165V16.0857C9.27 16.2572 9.414 16.3944 9.594 16.3944H11.304C11.484 16.3944 11.628 16.2572 11.628 16.0857V8.71165C11.628 8.54016 11.484 8.40297 11.304 8.40297ZM14.724 5.69344H13.014C12.834 5.69344 12.69 5.83063 12.69 6.00212V16.0857C12.69 16.2572 12.834 16.3944 13.014 16.3944H14.724C14.904 16.3944 15.048 16.2572 15.048 16.0857V6.00212C15.066 5.83063 14.904 5.69344 14.724 5.69344ZM11.412 5.07608L14.346 1.95498C14.634 1.6463 14.598 1.18328 14.292 0.926044C13.968 0.651662 13.482 0.685959 13.212 0.977491L10.512 3.84136L8.316 3.78991C8.082 3.78991 7.884 3.87566 7.722 4.03L4.284 7.63127L3.546 6.99676L2.79 10.2893L6.156 9.26041L5.418 8.6259L8.622 5.26472L10.818 5.31616C11.052 5.33331 11.268 5.24757 11.412 5.07608Z"
              fill="#394960"
            />
          </svg>
        );
      case "table":
        return <TableChartOutlinedIcon />;
      case "PostAdd":
        return <PostAddIcon />;
      case "visible":
        return <span class="material-symbols-outlined"> visibility </span>;
      case "copy":
        return <span class="material-symbols-outlined">content_copy </span>;
      case "delete":
        return <span class="material-symbols-outlined">delete </span>;
      case "compare":
        return <span class="material-symbols-outlined"> compare</span>;
      case "add":
        return <span class="material-symbols-outlined">add</span>;
      case "expandCircleDownIcon":
        return (
          <span class="material-symbols-outlined">expand_circle_down</span>
        );
      case "time":
        return <span class="material-symbols-outlined">schedule </span>;
      case "filter":
        return <span class="material-symbols-outlined">filter_alt</span>;
      case "revert":
        return <UndoIcon />;
      case "reload":
        return <span class="material-symbols-outlined">restart_alt</span>;
      case "menu":
        return <span class="material-symbols-outlined">more_vert</span>;
      case "reloadAuto":
        return <span class="material-symbols-outlined">autorenew</span>;
      case "excel":
        return <RiFileExcel2Fill />;
      case "csv":
        return <GrDocumentCsv />;

      default:
        return null;
    }
  };
  const renderPopupVIew = () => {
    return (
      <>
        {showMessage && (
          <SnackBar
            message={message}
            variant="success"
            handleClose={handleMessageClose}
          />
        )}
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              {data.iconType ? (
                IconSelector(data, popupState)
              ) : (
                <Button
                  className={data?.isIAIconButton && "ia-icon-button"}
                  variant={
                    data.varient === "contained"
                      ? "primary"
                      : data.varient === "text"
                      ? "url"
                      : "secondary"
                  }
                  size={
                    !data.size || data.size == "medium" ? "normal" : "small"
                  }
                  {...bindTrigger(popupState)}
                >
                  {label}
                </Button>
              )}
              <Menu {...bindMenu(popupState)}>
                {data.options.map((option, index) => (
                  <>
                    {option.dependentOn ? (
                      <>
                        {" "}
                        {dependentInfo[`${option.dependentOn}`] ? null : (
                          <>
                            <MenuItem
                              onClick={() => onAction(option.action, index)}
                            >
                              {option.name}
                            </MenuItem>
                          </>
                        )}{" "}
                      </>
                    ) : (
                      <>
                        {option?.iconType ? (
                          <>
                            <IconMenuItem
                              onClick={() => {
                                if (Array.isArray(option.action)) {
                                  option.action.forEach((action, index) =>
                                    onAction(action, index, popupState)
                                  );
                                } else
                                  onAction(option.action, index, popupState);
                              }}
                              leftIcon={IconRenderer(option?.iconType)}
                              label={option.name}
                              className={"icon-dropdown"}
                              sx={{
                                display: "flex",
                                alignItems: "center!important",
                                paddingLeft: "8px",
                                paddingRight: "4px",
                              }}
                            />
                          </>
                        ) : (
                          <MenuItem
                            onClick={() => {
                              updateLabel(option.name);
                              if (Array.isArray(option.action)) {
                                option.action.forEach((action, index) =>
                                  onAction(action, index, popupState)
                                );
                              } else onAction(option.action, index, popupState);
                            }}
                          >
                            {option.name}
                          </MenuItem>
                        )}
                      </>
                    )}{" "}
                  </>
                ))}
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </>
    );
  };

  const updateLabel = (name) => {
    if (data?.dynamicLabel) {
      setLabel(name);
    }
  };
  return (
    <>
      {open ? (
        <CustomModal
          show={open}
          {...data.options[itemIndex]["action"]}
          handleClose={handleClose}
        />
      ) : null}
      {props["isDivNotRequired"] ? (
        <>{renderPopupVIew()}</>
      ) : (
        <div style={{ textAlign: data && data.alignment }}>
          {renderPopupVIew()}
        </div>
      )}
    </>
  );
}
