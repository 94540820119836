import React, {useState} from "react";
import { ButtonGroup, Button } from "impact-ui";

export default function GroupOfButton({ details, onAction, dependentInfo, getIcon , defaultActiveIndex}) {
    let [activeIndex, setActiveIndex] = useState(defaultActiveIndex);
  return (
    <ButtonGroup className="button-group-impact">
      {details.map((item, index) => {
        return (
          <>
            {item?.iconType ? (
                <Button
                variant="secondary"
                  icon={() => getIcon(item, index)}
                  size={
                    !item.size || item.size === "medium" ? "normal" : "small"
                  }
                  disabled={
                    item?.disabledBy
                      ? !dependentInfo[`${item["disabledBy"]}`]
                      : false
                  }
                  onClick={() => {
                    setActiveIndex(index);
                    if (Array.isArray(item.action)) {
                      item.action.forEach((action) => onAction(action, index));
                    } else onAction(item.action, index);
                  }}
                  active={activeIndex === index}
                >
                  {item.name}
                </Button>
            ) : (
              <Button
              variant="secondary"

                //icon={() => getIcon(item, index)}
                size={!item.size || item.size === "medium" ? "normal" : "small"}
                disabled={
                  item?.disabledBy
                    ? !dependentInfo[`${item["disabledBy"]}`]
                    : false
                }
                onClick={() => {
                  setActiveIndex(index);
                  if (Array.isArray(item.action)) {
                    item.action.forEach((action) => onAction(action, index));
                  } else onAction(item.action, index);
                }}
                active={activeIndex === index}
              >
                {item.name}
              </Button>
            )}
          </>
        );
      })}
    </ButtonGroup>
  );
}
