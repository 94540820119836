import React from "react";
// Impact UI
import { Button } from "impact-ui";

export default function ButtonWithIconLabel({
  item,
  onAction,
  index,
  dependentInfo,
  getIcon
}) {
  return (
    <Button
    className={item.className || ""}
    sx={{
        backgroundColor:
            item.varient === "contained"
                ? "#0055AF"
                : null,
        ...item?.buttonStyle,
    }}
    disabled={
        item?.disabledBy
            ? !dependentInfo[`${item["disabledBy"]}`]
            : false
    }
    variant={item.varient === "contained"? "primary": item.varient === 'text'? "url": "secondary"}
    size={!item.size || item.size == "medium" ? "normal" : "small"}
    title={item.name}
    icon={() => getIcon(item, index)}
    // disabled={obj.disableFeat && diable}
    onClick={() => {
        if (Array.isArray(item.action)) {
            item.action.forEach((action) =>
                onAction(action, index)
            );
        } else onAction(item.action, index);
    }}>
    {item.name}
    {/* <Text style={item?.style}>{item.name}</Text> */}
</Button>
  );
}
